import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
var RouteArrow = function (_a) {
    var _b;
    var className = _a.className, timeInRouteClassName = _a.timeInRouteClassName, flightNumberClassName = _a.flightNumberClassName, routeClassName = _a.routeClassName, circleClassName = _a.circleClassName, timeInRoute = _a.timeInRoute, flightNumber = _a.flightNumber, mobile = _a.mobile, stopCount = _a.stopCount;
    var theme = useTheme('DesktopFlightRoute').RouteArrowStyles;
    var t = useTranslation('DesktopFlight').t;
    return (React.createElement("div", { className: cn(theme.container, (_b = {},
            _b[theme.mobile] = mobile,
            _b), className) },
        React.createElement("div", { className: cn(theme.route, routeClassName) },
            React.createElement("div", { className: cn(theme.circle, circleClassName) }),
            React.createElement("div", { className: cn(theme.timeInRoute, timeInRouteClassName) }, timeInRoute),
            React.createElement("div", { className: cn(theme.circle, circleClassName, theme.circle_right) })),
        flightNumber && !stopCount && (React.createElement("div", { className: cn(theme.flightNumber, flightNumberClassName) }, flightNumber)),
        stopCount > 0 && (React.createElement("div", { className: cn(theme.flightNumber, theme.stop) },
            stopCount,
            " ",
            t(stopCount > 1 ? 'stops' : 'stop')))));
};
export default RouteArrow;
