import { __assign } from "tslib";
import * as React from 'react';
import CheckoutAdditionalService from '../../../../CheckoutAdditionalService';
import { useTranslation } from 'react-i18next';
import CommonContent from '../CommonContent';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
var Meal = function (props) {
    var t = useTranslation('Checkout').t;
    var theme = useTheme('RefundSummary').CommonContent;
    return (React.createElement(CheckoutAdditionalService, { header: t('Meal'), description: t('Feel the taste in flight!'), className: cn(theme.serviceCard, theme.serviceCard_meal), isSelected: true, addClick: function () { return null; } },
        React.createElement(CommonContent, __assign({}, props))));
};
export default Meal;
