import * as React from 'react';
import cn from 'classnames';
import FlightSelector from '../FlightSelector/FlightSelector';
import { useTheme } from '../../../theme';
import { useUpgradeFlightClass } from '../../hooks/UpgradeFlightClass.hook';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
import Toolbar from '../../../Toolbar';
import { RefundSummary } from '../../../RefundSummary';
import UpgradeInfo from '../UpgradeInfo/UpgradeInfo';
initI18n('UpgradeFlightClass');
var UpgradeFlightClass = function (_a) {
    var onBackClick = _a.onBackClick, contentClassName = _a.contentClassName, loading = _a.loading, onSaveServices = _a.onSaveServices;
    var css = useTheme('UpgradeFlightClass').UpgradeFlightClass;
    var t = useTranslation('UpgradeFlightClass').t;
    var model = useUpgradeFlightClass({ onBackClick: onBackClick, loading: loading, onSaveServices: onSaveServices });
    var title = React.useMemo(function () {
        return model.step === 'select' ? t('Select segments') : t('Segments for upgrade');
    }, [model.step]);
    var nextButtonText = React.useMemo(function () {
        return model.step === 'select' ? t('Continue') : t('Accept');
    }, [model.step]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(css.wrapper, contentClassName) },
            React.createElement(UpgradeInfo, { service: model.service }),
            React.createElement("div", { className: css.flights__wrapper },
                React.createElement("div", { className: css.flights__title }, t(title)),
                React.createElement("div", { className: css.flights },
                    React.createElement(FlightSelector, { type: model.step, selectedSegments: model.selectedSegments, onChange: model.toggleSegment, flights: model.flights, transferInfo: model.transferDurationBySegments }))),
            model.step === 'review' && (React.createElement(RefundSummary, { order: model.order, gdsServicesRefund: model.gdsServicesRefund, isLoading: model.refundServicesLoading }))),
        React.createElement(Toolbar, { nextButtonText: nextButtonText, isLoading: model.isServicesSaving, totalPrice: model.totalPrice, onButtonClick: model.toolbarCallbacks.next, onBackButtonClick: model.toolbarCallbacks.back, buttonDisabled: model.selectedSegments.length === 0 })));
};
export default UpgradeFlightClass;
