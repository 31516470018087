import * as UpgradeFlightClass from './components/UpgradeFlightClass/UpgradeFlightClass.css';
import * as UpgradeInfo from './components/UpgradeInfo/UpgradeInfo.css';
import * as FlightSelector from './components/FlightSelector/FlightSelector.css';
import * as SelectableSegment from './components/SelectableSegment/SelectableSegment.css';
export default {
    UpgradeFlightClass: UpgradeFlightClass,
    UpgradeInfo: UpgradeInfo,
    FlightSelector: FlightSelector,
    SelectableSegment: SelectableSegment
};
