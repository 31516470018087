import { __read, __spreadArray } from "tslib";
import React from 'react';
import { getProductsToExchange } from './utils';
import { useGetServicesRefundInfoQuery } from '@websky/graphql';
export function useRefundSummary(orderId, passengers, selectedPassengerIds, selectedSegmentIds, allFlightToExchange) {
    var services = [];
    selectedPassengerIds.forEach(function (passengerId) {
        var _a, _b, _c, _d, _e;
        var passenger = passengers.find(function (p) { return p.id === passengerId; });
        if ((_b = (_a = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) {
            services.push.apply(services, __spreadArray([], __read((_d = (_c = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services), false));
        }
        if ((_e = passenger === null || passenger === void 0 ? void 0 : passenger.services) === null || _e === void 0 ? void 0 : _e.seats) {
            passenger.services.seats.forEach(function (seat) {
                if (!seat.product) {
                    return;
                }
                services.push({
                    segmentIds: [seat.segment.id],
                    products: [seat.product]
                });
            });
        }
    });
    var selectedProductIds = React.useMemo(function () {
        return getProductsToExchange(services, allFlightToExchange ? [] : selectedSegmentIds);
    }, [services, selectedSegmentIds, allFlightToExchange]);
    var _a = useGetServicesRefundInfoQuery({
        variables: {
            params: {
                orderId: orderId,
                productIds: selectedProductIds
            }
        },
        skip: !selectedProductIds.length
    }), data = _a.data, loading = _a.loading;
    return {
        gdsServicesRefund: data,
        loading: loading
    };
}
