import * as React from 'react';
import { useTheme } from '../../../../theme';
import cn from 'classnames';
var Point = function (_a) {
    var _b;
    var className = _a.className, time = _a.time, airport = _a.airport, arrival = _a.arrival, thin = _a.thin, mobile = _a.mobile, date = _a.date;
    var theme = useTheme('DesktopFlightRoute').PointStyles;
    return (React.createElement("div", { className: cn(theme.point, (_b = {},
            _b[theme.point_arrival] = arrival,
            _b[theme.point_thin] = thin,
            _b[theme.mobile] = mobile,
            _b), className) },
        React.createElement("div", { className: theme.date__container },
            date && React.createElement("div", { className: theme.date }, date),
            React.createElement("div", { className: theme.time }, time),
            React.createElement("div", { className: theme.airport },
                airport.city.name ? airport.city.name : airport.name,
                " (",
                airport.iata,
                ")"))));
};
export default Point;
