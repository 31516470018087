import { __assign } from "tslib";
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ExareStatus } from '@websky/graphql';
import { getSelectedFares } from '../../../store/newFlights/selectors';
import Notice from '../../../../../RefundSummary/components/Notice/Notice';
import CreateRequestWrapper from '../CreateRequest/CreateRequestWrapper';
import { setLastExchange } from '../../../store/order/actions';
import { getOrder, isAutoMode } from '../../../store/order/selectors';
import SelectedFlights from './SelectedFlights/SelectedFlights';
import RefundSummary from '../../../../../RefundSummary/components/RefundSummary';
import { useRefundSummary } from '../../../../../RefundSummary/hooks';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
import { getFlightIdsToExchange } from '../../../store/flightsToExchange/selectors';
import { getIsAllFlights } from '../../../store/selectors';
var Request = function (_a) {
    var goToPayment = _a.goToPayment, isNewRequest = _a.isNewRequest, goToFlights = _a.goToFlights, setExchangeSuccess = _a.setExchangeSuccess, refreshOrder = _a.refreshOrder, clearSelectedFares = _a.clearSelectedFares, flightId = _a.flightId, isLoading = _a.isLoading, exchangePrices = _a.exchangePrices;
    var t = useTranslation('Exchange').t;
    var dispatch = useDispatch();
    var order = useSelector(getOrder);
    var selectedPassengers = useSelector(getSelectedPassengerIds);
    var selectedSegmentItems = useSelector(getFlightIdsToExchange);
    var selectedSegmentIds = selectedSegmentItems.map(function (i) { return i.key; });
    var isAllFlightToExchange = useSelector(getIsAllFlights);
    var selectedFlight = useSelector(getSelectedFares);
    var autoMode = useSelector(isAutoMode);
    var firstExarePrice = selectedFlight === null || selectedFlight === void 0 ? void 0 : selectedFlight[0].exarePrice;
    var priceToPay = firstExarePrice === null || firstExarePrice === void 0 ? void 0 : firstExarePrice.priceToPay;
    var _b = useRefundSummary(order.id, order.travellers, selectedPassengers, selectedSegmentIds, isAllFlightToExchange), gdsServicesRefund = _b.gdsServicesRefund, loading = _b.loading;
    var setExchangeOrder = function (order, openRequest) {
        dispatch(setLastExchange(order));
        if (order.status === ExareStatus.AwaitingPayment) {
            if (!openRequest) {
                goToPayment();
            }
        }
        else if (order.status === ExareStatus.Finished) {
            if (priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.amount) {
                setExchangeSuccess();
            }
            else {
                refreshOrder();
            }
        }
        else {
            clearSelectedFares();
        }
    };
    var prices = React.useMemo(function () {
        var _a, _b;
        if (exchangePrices) {
            return exchangePrices;
        }
        if (autoMode && firstExarePrice) {
            return {
                priceToPay: priceToPay,
                charges: {
                    amount: +(((_a = firstExarePrice.agencyChargesForExare) === null || _a === void 0 ? void 0 : _a.amount) || 0) +
                        +(((_b = firstExarePrice.exchangeAirlinePenalty) === null || _b === void 0 ? void 0 : _b.amount) || 0),
                    currency: priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.currency
                }
            };
        }
        return null;
    }, [autoMode, selectedFlight, exchangePrices]);
    return (React.createElement("div", null,
        React.createElement(SelectedFlights, { selectedFlights: selectedFlight.map(function (flight) { return (__assign(__assign({}, flight.fare.flightInfo), { segmentGroups: order.flight.segmentGroups, fares: [] })); }), prices: prices, goToFlights: goToFlights }),
        React.createElement(RefundSummary, { order: order, gdsServicesRefund: gdsServicesRefund, isLoading: loading }),
        !autoMode && (React.createElement(Notice, { type: "notice", header: t('Note'), text: t('The possibility and cost of changes will be calculated by the operator after sending the application. The answer will be sent to your E-mail and is also available on the order page.') })),
        React.createElement(Notice, { type: "reject", header: t('Exchange restrictions'), text: t('After submitting the request, the seats you have selected will be canceled.') }),
        React.createElement(CreateRequestWrapper, { onOrderCreated: setExchangeOrder, flightId: flightId, isLoading: loading || isLoading, refreshOrder: refreshOrder })));
};
export default Request;
