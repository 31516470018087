import { __assign } from "tslib";
export var reducer = function (state, action) {
    switch (action.type) {
        case 'setTotalPrice': {
            return __assign(__assign({}, state), { totalPrice: action.payload });
        }
        case 'toggleSegment':
            if (state.selectedSegments.includes(action.payload)) {
                return __assign(__assign({}, state), { selectedSegments: state.selectedSegments.filter(function (segment) { return segment !== action.payload; }) });
            }
            else {
                return __assign(__assign({}, state), { selectedSegments: state.selectedSegments.concat([action.payload]) });
            }
        case 'setStep':
            return __assign(__assign({}, state), { step: action.payload });
    }
};
