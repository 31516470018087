import { __awaiter, __generator, __read } from "tslib";
import React, { useCallback, useMemo } from 'react';
import './UpgradeFlightClass.css';
import { useTheme } from '../../../../../theme';
import { getTravellersMap, initI18n } from '../../../../../utils';
import { useToggleable } from '../../../../../hooks';
import { useRenderers } from '../../../../../renderProps';
import CheckoutAdditionalService from '../../../../';
import { UpgradeFlightClass } from '../../../../../UpgradeFlightClass';
import { useDispatch, useSelector } from 'react-redux';
import { getIsUpgradeClassSelectedForAllSegments, getIsUpgradeClassServiceSelected, getOrder, getUpgradeClassServices } from '../../../../../Checkout/store/order/selectors';
import FullscreenDialog from '../../../../../FullscreenDialog';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import UpgradeFlightClassSummary from '../../../../../ServiceSummary/services/UpgradeFlightClassSummary';
import { useTranslation } from 'react-i18next';
import { useCart } from '../../../../../Checkout/components/Checkout/Stepbar/hooks';
import { fillOrder } from '../../../../../Checkout/store/order/actions';
initI18n('UpgradeFlightClass');
var UpgradeFlightClassCheckout = function () {
    var _a;
    var t = useTranslation('Checkout').t;
    var service = useSelector(getUpgradeClassServices)[0];
    var order = useSelector(getOrder);
    var onDeleteItem = useCart().onDeleteItem;
    var dispatch = useDispatch();
    if (!(service === null || service === void 0 ? void 0 : service.canBeAdded)) {
        return null;
    }
    var isSelected = useSelector(getIsUpgradeClassServiceSelected);
    var isSelectedForAllSegments = useSelector(getIsUpgradeClassSelectedForAllSegments);
    var css = useTheme('CheckoutAdditionalService').UpgradeFlightCLassCheckoutService;
    var _b = useToggleable(false), isOpen = _b.isOpen, open = _b.open, close = _b.close;
    var CustomCheckoutAdditionalService = useRenderers().CheckoutAdditionalService;
    var ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    var _c = __read(useSaveOrderServicesMutation(), 2), saveOrderServices = _c[0], _d = _c[1], data = _d.data, loading = _d.loading;
    // const totalPrice = useMemo(() => {
    // 	const totalPrice: Money = {
    // 		amount: 0,
    // 		currency: order?.price?.total?.currency
    // 	};
    //
    // 	for (const traveller of order.travellers) {
    // 		const travellerService = traveller.services?.gdsServices?.services?.find(
    // 			travellerService => travellerService.serviceId === service.id
    // 		);
    //
    // 		if (travellerService) {
    // 			totalPrice.amount += travellerService.count * service.price.amount;
    // 		}
    // 	}
    //
    // 	return totalPrice;
    // }, [order.travellers]);
    var handleSaveServices = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveOrderServices({
                        variables: params
                    })];
                case 1:
                    data = (_a.sent()).data;
                    if (data.SaveOrderServices) {
                        dispatch(fillOrder(data.SaveOrderServices));
                    }
                    close();
                    return [2 /*return*/];
            }
        });
    }); }, [data, loading, saveOrderServices]);
    var buttons = useMemo(function () {
        if (isSelectedForAllSegments) {
            return null;
        }
        if (isSelected) {
            return [
                {
                    action: open,
                    text: t('Change'),
                    buttonType: 'change',
                    variant: 'text'
                }
            ];
        }
        else {
            return [
                {
                    action: open,
                    text: t('Add'),
                    buttonType: 'change',
                    variant: 'common'
                }
            ];
        }
    }, [isSelected, isSelectedForAllSegments]);
    return (React.createElement(React.Fragment, null,
        React.createElement(UpgradeFlightClassSummary, { flight: order.flight, travellers: order.travellers, services: order.additionalServices, onServiceDelete: onDeleteItem, travellersById: getTravellersMap(order.travellers) }, function (children) {
            return (React.createElement(ServiceWrapper, { buttons: buttons, header: service.name, className: css.upgradeFlightClass, description: service.description, isSelected: isSelected, addClick: open, priceFrom: service.price, priceTotal: order.price.vip }, children));
        }),
        React.createElement(FullscreenDialog, { isOpen: isOpen, onClose: close, contentClassName: css.content, fadeTransition: true },
            React.createElement(UpgradeFlightClass, { onBackClick: close, onSaveServices: handleSaveServices, loading: loading }))));
};
export default UpgradeFlightClassCheckout;
