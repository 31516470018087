import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getOrder, getOrderId, getRecheckAvailabilityTimout, getRecommendedBaggage } from './store/order/selectors';
import { fillOrder } from './store/order/actions';
import { useDispatch, useSelector } from 'react-redux';
import { BAGGAGE_RECOMMENDATION_SHOWN, get, set } from '../cache';
import { setMultipleSelectedServices } from './store/selectedServices/actions';
import { useCheckOrderAvailabilityMutation, OrderStatus, OrderAdditionalServiceGdsServiceServiceType, useCheckinInfoQuery, useGetServicesRefundInfoQuery } from '@websky/graphql';
import { useIataCode } from '../hooks';
import { calculateCheckinAvailability } from './utils';
import { getPassengersSelectedProductIds } from '../RefundSummary/utils';
export var usePreselectedBaggageSelect = function (selectBaggageWithoutModal) {
    var dispatch = useDispatch();
    var orderId = useSelector(getOrderId);
    var recommendedBaggage = useSelector(getRecommendedBaggage);
    var localStorageKey = "".concat(BAGGAGE_RECOMMENDATION_SHOWN, "_").concat(orderId);
    var recommendationWasShown = get(localStorageKey);
    var handleSelectAllServices = function () {
        var servicesToSelect = [];
        recommendedBaggage.forEach(function (service) {
            servicesToSelect.push({
                service: {
                    id: service.baggage.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                    confirmedCount: service.confirmedCount,
                    price: service.baggage.price
                },
                segmentIds: service.segmentIds,
                serviceId: service.baggage.id,
                count: service.count,
                passengerId: service.travellerId,
                segmentId: service.segmentIds[0]
            });
        });
        if (servicesToSelect.length) {
            dispatch(setMultipleSelectedServices(servicesToSelect));
        }
    };
    useEffect(function () {
        if (!recommendationWasShown) {
            set(localStorageKey, true);
            if (selectBaggageWithoutModal) {
                handleSelectAllServices();
            }
        }
    }, [recommendationWasShown]);
};
export var useAvailabilityRecheck = function (setActualizationOpen) {
    var timeoutToReCheckAvailability = useSelector(getRecheckAvailabilityTimout) * 1000;
    var orderId = useSelector(getOrderId);
    var orderStatus = useSelector(getOrder).status;
    var _a = __read(useState(null), 2), timeout = _a[0], setNewTimeout = _a[1];
    var dispatch = useDispatch();
    var needToCheckAvailability = orderStatus === OrderStatus.New || orderStatus === OrderStatus.Booked;
    var _b = __read(useCheckOrderAvailabilityMutation({ fetchPolicy: 'no-cache' }), 2), recheckAvailability = _b[0], data = _b[1].data;
    var recheckIfTimoutExceed = function () {
        if (needToCheckAvailability) {
            recheckAvailability({ variables: { id: orderId } });
        }
    };
    useEffect(function () {
        var _a, _b;
        if (!(data === null || data === void 0 ? void 0 : data.CheckOrderAvailability)) {
            return function () { return setActualizationOpen(false); };
        }
        if (((_a = data === null || data === void 0 ? void 0 : data.CheckOrderAvailability) === null || _a === void 0 ? void 0 : _a.status) === OrderStatus.Confirmed) {
            clearTimeout(timeout);
            setNewTimeout(null);
            return function () { return setActualizationOpen(false); };
        }
        if (((_b = data === null || data === void 0 ? void 0 : data.CheckOrderAvailability) === null || _b === void 0 ? void 0 : _b.status) === OrderStatus.Cancelled) {
            clearTimeout(timeout);
            setNewTimeout(null);
            return function () { return setActualizationOpen(false); };
        }
        dispatch(fillOrder(data.CheckOrderAvailability));
        clearTimeout(timeout);
        setNewTimeout(null);
        if (timeoutToReCheckAvailability === 0) {
            setNewTimeout(setTimeout(recheckIfTimoutExceed, timeoutToReCheckAvailability));
        }
        setActualizationOpen(true);
        return function () {
            setActualizationOpen(false);
        };
    }, [timeoutToReCheckAvailability]);
    useEffect(function () {
        if (!timeout) {
            setNewTimeout(setTimeout(recheckIfTimoutExceed, timeoutToReCheckAvailability));
        }
    }, [timeout, timeoutToReCheckAvailability]);
};
export var useIsCheckinAvailable = function (order) {
    var _a;
    var isConfirmed = order.status === OrderStatus.Confirmed;
    var iataCode = useIataCode();
    var manualCheckIataCodes = ['DV'];
    var getCheckinInfoResponse = useCheckinInfoQuery({
        skip: !isConfirmed || manualCheckIataCodes.includes(iataCode),
        variables: {
            params: {
                aviaOrderId: order.id
            }
        }
    }).data;
    if (manualCheckIataCodes.includes(iataCode) && isConfirmed) {
        return calculateCheckinAvailability(order);
    }
    return (_a = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _a === void 0 ? void 0 : _a.isAvailable;
};
export var useServicesRefundInfo = function (orderId, travellers, selectedPassengerIds, selectedSegmentIds, isAllFlightToExchange, skip) {
    var productIds = React.useMemo(function () {
        return getPassengersSelectedProductIds(travellers, selectedPassengerIds, selectedSegmentIds, isAllFlightToExchange);
    }, [travellers, selectedPassengerIds, selectedSegmentIds, isAllFlightToExchange]);
    return useGetServicesRefundInfoQuery({
        variables: { params: { orderId: orderId, productIds: productIds } },
        skip: !productIds.length || skip
    });
};
