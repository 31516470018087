import cn from 'classnames';
import SubsidySuggestPopup from './components/SubsidySuggestPopup/SubsidySuggestPopup.css';
import SubsidyRejectPopup from './components/SubsidyRejectPopup/SubsidyRejectPopup.css';
import Checkin from './components/Checkin/Checkin.css';
import Input from './components/Input/Input.css';
import RetrieveBooking from './components/RetrieveBooking/RetrieveBooking.css';
import SearchForm from './components/SearchForm/SearchForm.css';
import SearchFormDatepicker from './components/SearchFormDatepicker/SearchFormDatepicker.css';
import OrderStatus from './components/OrderCard/OrderStatus/OrderStatus.css';
import OrderChange from './components/OrderCard/OrderChange/OrderChange.css';
import CommonSelect from './components/Exchange/CommonSelect/CommonSelect.css';
import PetPopup from './components/PetPopup/PetPopup.css';
import SelectedFlights from './components/Exchange/SelectedFlights/SelectedFlights.css';
import Notice from './components/Exchange/Notice/Notice.css';
import Inner from './components/Exchange/Inner/Inner.css';
import Choice from './components/Exchange/Choice/Choice.css';
import Chip from './components/Exchange/Chip/Chip.css';
import SearchFlights from './components/Exchange/SearchFlights/SearchFlights.css';
import CreateRequest from './components/Exchange/CreateRequest/CreateRequest.css';
import CommonContent from './components/Exchange/CommonContent/CommonContent.css';
import StepTitle from './components/Refund/StepTitle/StepTitle.css';
import Refund from './components/Refund/Refund.css';
import RefundPassenger from './components/Refund/Passenger/Passenger.css';
import WhiteButton from './components/Button/WhiteButton/WhiteButton.css';
import ActionButton from './components/Button/ActionButton/ActionButton.css';
import Reason from './components/Refund/Reason/Reason.css';
import PromoCode from './components/PromoCode/PromoCode.css';
import PayByMiles from './components/PayByMiles/PayByMiles.css';
import ReasonForm from './components/Refund/ReasonForm/ReasonForm.css';
import RefundPapper from './components/Refund/RefundPapper/RefundPapper.css';
import RequestForm from './components/Refund/RequestForm/RequestForm.css';
import RefundInfoModal from './components/Refund/RefundInfoModal/RefundInfoModal.css';
import SegmentCommon from './components/Segment/SegmentCommon/SegmentCommon.css';
import SelectedPassenger from './components/SelectedPassenger/SelectedPassenger.css';
import AddPassenger from './components/AddPassenger/AddPassenger.css';
import PaymentForm from './components/PaymentForm/PaymentForm.css';
import Notification from './components/Notification/Notification.css';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService.css';
import Seats from './components/CheckoutAdditionalService/Seats/Seats.css';
import Animal from './components/CheckoutAdditionalService/Animal/Animal.css';
import CheckoutAdditionalServiceMeal from './components/CheckoutAdditionalService/CheckoutAdditionalServiceMeal/CheckoutAdditionalServiceMeal.css';
import CheckoutAdditionalServiceBaggage from './components/CheckoutAdditionalService/CheckoutAdditionalServiceBaggage/CheckoutAdditionalServiceBaggage.css';
import Insurance from './components/CheckoutAdditionalService/Insurance/Insurance.css';
import Aeroexpress from './components/CheckoutAdditionalService/Aeroexpress/Aeroexpress.css';
import PriorityBoarding from './components/CheckoutAdditionalService/PriorityBoarding/PriorityBoarding.css';
import Business from './components/CheckoutAdditionalService/Business/Business.css';
import CapsuleHotel from './components/CheckoutAdditionalService/CapsuleHotel/CapsuleHotel.css';
import OrderPassengers from './components/OrderCard/OrderPassengers/OrderPassengers.css';
import OrderBooking from './components/OrderCard/OrderBooking/OrderBooking.css';
import CommonItem from './components/OrderCard/CommonItem/CommonItem.css';
import Meal from './components/Meal/Meal.css';
import SegmentTabs from './components/SegmentTabs/SegmentTabs.css';
import IncludedBaggage from './components/Baggage/IncludedBaggage/IncludedBaggage.css';
import AdditionalBaggage from './components/Baggage/AdditionalBaggage/AdditionalBaggage.css';
import BaggageCard from './components/Baggage/BaggageCard/BaggageCard.css';
import BaggageIcon from './components/Baggage/BaggageIcon/BaggageIcon.css';
import Baggage from './components/Baggage/Baggage.css';
import BaggageList from './components/Baggage/BaggageList/BaggageList.css';
import BaggageTotalPriceBlock from './components/Baggage/BaggageTotalPriceBlock/BaggageTotalPriceBlock.css';
import UpgradeBaggageDialog from './components/Baggage/UpgradeBaggageDialog/UpgradeBaggageDialog.css';
import SpecialEquipment from './components/Baggage/SpecialEquipment/SpecialEquipment.css';
import SwitchV2 from './components/Switch/SwitchV2/SwitchV2.css';
import SegmentTab from './components/SegmentTabs/SegmentTab/SegmentTab.css';
import Airplane from './components/SeatMap/Airplane/Airplane.css';
import AirplaneInfo from './components/SeatMap/AirplaneInfo/AirplaneInfo.css';
import Passenger from './components/SeatMap/Passenger/Passenger.css';
import Passengers from './components/SeatMap/Passengers/Passengers.css';
import Disclaimer from './components/SeatMap/Disclaimer/Disclaimer.css';
import Controls from './components/SeatMap/Controls/Controls.css';
import SeatsInfo from './components/SeatMap/SeatsInfo/SeatsInfo.css';
import Seat from './components/SeatMap/Seat/Seat.css';
import SeatMap from './components/SeatMap/SeatMap.css';
import SeatTooltip from './components/SeatMap/SeatTooltip/SeatTooltip.css';
import Toolbar from './components/Toolbar/Toolbar.css';
import Checkout from './components/Checkout/Checkout.css';
import OtherDates from './components/Results/OtherDates/OtherDates.css';
import Calendar from './components/Results/Calendar/Calendar.css';
import WeekCalendar from './components/WeekCalendar/WeekCalendar.css';
import Sortings from './components/Results/FlightsList/Sortings/Sortings.css';
import DesktopFlightRoute from './components/DesktopFlightRoute/DesktopFlightRoute.css';
import FareGroup from './components/FareGroup/FareGroup.css';
import FlightCard from './components/FlightCard/FlightCard.css';
import DesktopFlight from './components/DesktopFlight/DesktopFlight.css';
import CompareFares from './components/CompareFares/CompareFares.css';
import FareConditions from './components/FareConditions/FareCondition.css';
import Modal from './components/Modal/Modal.css';
import Location from './components/DesktopFlight/Location/Location.css';
import Stepbar from './components/Stepbar/Stepbar.css';
import QuickSearchForm from './components/QuickSearchForm/QuickSearchForm.css';
import Contacts from './components/Contacts/Contacts.css';
import PassengerFormHeader from './components/PassengerForm/Header/Header.css';
import PassengerSwitch from './components/Passenger/Switch/Switch.css';
import Select from './components/Passenger/Select/Select.css';
import PassengerForm from './components/PassengerForm/PassengerForm.css';
import SelectedFareGroup from './components/DesktopFlight/SelectedFareGroup/SelectedFareGroup.css';
import MobileStep from './components/Refund/MobileStep/MobileStep.css';
import RefundWrapper from './components/Refund/Wrapper/Wrapper.css';
import RefundTicket from './components/Refund/Ticket/Ticket.css';
import Ticket from './components/Refund/Ticket/Ticket.css';
import ExchangeDialog from './components/Exchange/Dialog/Dialog.css';
import Amenities from './components/Amenities/Amenities.css';
import SelectedFares from './components/Results/SelectedFares/SelectedFares.css';
import SuggestionModalContent from './components/SuggestionModalContent/SuggestionModalContent.css';
import BaggagePage from './components/BaggagePage/BaggagePage.css';
import DesktopSummaryFlightHeader from './components/DesktopSummaryFlight/Header/Header.css';
import SummaryFlight from './components/DesktopSummaryFlight/SummaryFlight.css';
import DesktopFlightInfo from './components/DesktopFlightInfo/DesktopFlightInfo.css';
import DesktopFlightInfoSegment from './components/DesktopFlightInfo/Segment/Segment.css';
import DesktopFlightInfoPoint from './components/DesktopFlightInfo/Point/Point.css';
import DialogStyles from './components/Service/DialogStyles/DialogStyles.css';
import PassengersDetailsPassenger from './components/PassengersDetails/Passenger/Passenger.css';
import AircraftPopupContent from './components/AircraftPopup/AircraftPopupContent/AircraftPopupContent.css';
import Results from './components/Results/Results.css';
import VipServiceSelect from './components/VipServiceSelect/VipServiceSelect.css';
import MobileDialog from './components/LocationAutocomplete/MobileDialog/MobileDialog.css';
import SlideBottom from './components/SlideBottom/SlideBottom.css';
import Categories from './components/Meal/Categories/Categories.css';
import LoginPageForm from './components/LoginPage/Form/Form.css';
import LoginPageHeader from './components/LoginPage/Header/Header.css';
import AccountModuleAccount from './components/AccountModule/Account/Account.css';
import LoginPageVerification from './components/LoginPage/Verification/Verification.css';
import LoginPageRegister from './components/LoginPage/Register/Register.css';
import LoginPageWelcome from './components/LoginPage/Welcome/Welcome.css';
import Timer from './components/Timer/Timer.css';
import OrderPreview from './components/OrderCard/OrderPreview/OrderPreview.css';
import MobileMenu from './components/Sidebar/MobileMenu/MobileMenu.css';
import Traveller from './components/Traveller/Traveller.css';
import TravellerForm from './components/TravellerForm/TravellerForm.css';
import LoyaltyConnect from './components/Loyalty/LoyaltyConnect/LoyaltyConnect.css';
import LoyaltyStub from './components/Loyalty/LoyaltyStub/LoyaltyStub.css';
import ForgotPassword from './components/Loyalty/ForgotPassword/ForgotPassword.css';
import PassengerFormGroup from './components/PassengerForm/Group/Group.css';
import DesktopFlightSelected from './components/DesktopFlight/DesktopFlightSelected/DesktopFlightSelected.css';
import TransferInfo from './components/TransferInfo/TransferInfo.css';
import MobileStepbarStep from './components/MobileStepbar/Step/Step.css';
import MobileStepbarStepContent from './components/MobileStepbar/StepContent/StepContent.css';
import OrderCard from './components/OrderCard/OrderCard.css';
import MobileSegmentFlat from './components/MobileSegmentInfo/MobileSegmentFlat/MobileSegmentFlat.css';
import MobileFlightStyles from './components/MobileFlight/FlightStyles/FlightStyles.css';
import MobileToolbar from './components/MobileToolbar/MobileToolbar.css';
import ThanksBanner from './components/Checkout/ThanksBanner/ThanksBanner.css';
import MobileScreen from './components/Insurance/MobileScreen/MobileScreen.css';
import PaymentStatus from './components/OrderCard/PaymentStatus/PaymentStatus.css';
import MobileSegment from './components/MobileSegment/MobileSegment.css';
import MobileScreenStyles from './components/Insurance/MobileScreenStyles/MobileScreenStyles.css';
import MobileStepbar from './components/MobileStepbar/MobileStepbar.css';
import ServiceWithSegments from './components/ServiceWithSegments/ServiceWithSegments.css';
import MobileFlight from './components/MobileFlight/MobileFlight.css';
import FareLock from './components/FareLock/FareLock.css';
import Switch from './components/Switch/Switch/Switch.css';
import SeatMapComponent from './components/SeatMap/Component/Component.css';
import PaymentResult from './components/PaymentResult/PaymentResult.css';
import AeroexpressModalContent from './components/Aeroexpress/ModalContent/ModalContent.css';
import InsuranceCard from './components/Insurance/InsuranceCard/InsuranceCard.css';
import DesktopFlightModalContent from './components/DesktopFlight/ModalContent/ModalContent.css';
import CheckoutAdditionalServiceLoyalty from './components/CheckoutAdditionalService/Loyalty/Loyalty.css';
import PromoLoader from './components/PromoLoader/PromoLoader.css';
import StatusMessage from './components/StatusMessage/StatusMessage.css';
import FlightNotAvailable from './components/Checkout/FlightNotAvailable/FlightNotAvailable.css';
import PaymentTimeLimitPage from './components/PaymentTimeLimitPage/PaymentTimeLimitPage.css';
import Link from './components/Link/Link.css';
import AdditionalServiceCard from './components/AdditionalServiceCard/AdditionalServiceCard.css';
import ResendTicketsForm from './components/ResendTicketsForm/ResendTicketsForm.css';
import MetasearchLoader from './components/MetasearchLoader/MetasearchLoader.css';
import BaggageDelivery from './components/CheckoutAdditionalService/BaggageDelivery/BaggageDelivery.css';
import CheckoutAdditionalServiceAnimal from './components/CheckoutAdditionalService/Animal/Animal.css';
import ScheduleForm from './components/ScheduleForm/ScheduleForm.css';
import ScheduleFlight from './components/CoreSchedule/ScheduleFlight/ScheduleFlight.css';
import ScheduleLocation from './components/CoreSchedule/Location/Location.css';
import ScheduleWeekDays from './components/CoreSchedule/WeekDays/WeekDays.css';
import ScheduleDateSwitcher from './components/CoreSchedule/DateSwitcher/DateSwitcher.css';
import ScheduleWeekpicker from './components/CoreSchedule/Weekpicker/Weekpicker.css';
import AdditionalServicesInsurance from './components/AdditionalServices/Insurance/Insurance.css';
import AdditionalServiceWrapper from './components/AdditionalServiceWrapper/AdditionalServiceWrapper.css';
import Datepicker from './components/SearchForm/components/SearchForm/Segments/Segment/Datepicker/Datepicker.css';
import LoyaltyInfo from './components/LoyaltyInfo/LoyaltyInfo.css';
import TicketExample from './components/TicketExample/TicketExample.css';
import ServiceSummary from './components/ServiceSummary/ServiceSummary.css';
import Money from './components/Money/Money.css';
import CheckoutAdditionalServiceUpgradeFlightClass from './components/CheckoutAdditionalService/CheckoutAdditionalServiceUpgradeFlightClass/CheckoutAdditionalServiceUpgradeFlightClass.css';
var theme = {
    SubsidySuggestPopup: {
        SubsidySuggestPopup: {
            container: SubsidySuggestPopup.container,
            passengerType: SubsidySuggestPopup.passengerType,
            age: SubsidySuggestPopup.age,
            info__icon: SubsidySuggestPopup.info__icon,
            decrease: SubsidySuggestPopup.decrease,
            increase: SubsidySuggestPopup.increase,
            passengers: SubsidySuggestPopup.passengers,
            item: SubsidySuggestPopup.item,
            notification: SubsidySuggestPopup.notification,
            buttons: SubsidySuggestPopup.buttons,
            newSearch: SubsidySuggestPopup.newSearch,
            continueButton: SubsidySuggestPopup.continueButton
        }
    },
    AdditionalServiceWrapper: {
        AdditionalServiceWrapper: {
            header: AdditionalServiceWrapper.header,
            button: AdditionalServiceWrapper.button
        }
    },
    Link: {
        Link: {
            link: Link.link
        }
    },
    MetasearchLoader: {
        MetasearchLoader: {
            logo: MetasearchLoader.logo
        }
    },
    SubsidyRejectPopup: {
        SubsidyRejectPopup: {
            container: SubsidyRejectPopup.container,
            flight: SubsidyRejectPopup.flight,
            passenger: SubsidyRejectPopup.passenger,
            flight__direction: SubsidyRejectPopup.flight__direction,
            notification: SubsidyRejectPopup.notification,
            buttons: SubsidyRejectPopup.buttons,
            newSearch: SubsidyRejectPopup.newSearch,
            continueButton: SubsidyRejectPopup.continueButton
        }
    },
    Meal: {
        Meal: {
            meal: Meal.meal,
            meal_selected: Meal.meal_selected,
            image: Meal.image,
            name: Meal.meal_name,
            price: Meal.meal_price,
            count: Meal.count,
            meal_fullSize: Meal.meal_fullSize
        },
        Details: {
            image: cn(Meal.image, Meal.details_image),
            name: Meal.details_name,
            trigger: Meal.trigger,
            price: Meal.details_price,
            addForAll: Meal.addForAll,
            clear: Meal.clear,
            passenger: Meal.passenger,
            passenger__index: Meal.passenger__index,
            content: Meal.details_content,
            description: Meal.details_description,
            button_empty: Meal.details_button_empty,
            total: Meal.total,
            description__row: Meal.description__row
        }
    },
    CloseIcon: {
        CloseIcon: {
            wrapper: Meal.closeIcon__wrapper
        }
    },
    MobileSegment: {
        MobileSegment: {
            wrapper: MobileSegment.wrapper,
            planeIcon: MobileSegment.planeIcon,
            flightInfo__cities: MobileSegment.flightInfo__cities,
            flightInfo__dates: MobileSegment.flightInfo__dates,
            flightInfo__time: MobileSegment.flightInfo__time,
            flightInfo__weather: MobileSegment.flightInfo__weather,
            fareGroup: MobileSegment.fareGroup,
            arrivalInfo__title: MobileSegment.arrivalInfo__title,
            arrivalInfo__item: MobileSegment.arrivalInfo__item,
            flightInfo__iatas: MobileSegment.flightInfo__iatas
        }
    },
    MealService: {
        List: {
            item: Meal.category__item,
            item_selected: Meal.category__item_selected,
            item__icon: Meal.category__icon,
            wrapper: Meal.wrapper
        },
        CategoriesStyles: {
            button__icon: Categories.button__icon,
            category__button: Categories.category__button,
            wrapper: Categories.wrapper,
            item__icon: Meal.category__icon
        },
        ServiceWrapper: {
            segmentTabs_meal: Meal.segmentTabs_meal,
            meals: Meal.meals,
            meals_singleMeal: Meal.meals_singleMeal
        }
    },
    Button: {
        WhiteButton: {
            button: WhiteButton.button
        },
        ActionButton: {
            button_small: cn(ActionButton.button, Meal.details_button_small),
            button: ActionButton.button
        }
    },
    Counter: {
        Counter: {
            button: Meal.counter_button,
            button_disabled: Meal.counter_button_disabled,
            button_readonly: Meal.counter_button_readonly
        }
    },
    SearchForm: {
        PromoCode: {
            promoCode: PromoCode.promoCode
        },
        PayByMiles: {
            switchBox: PayByMiles.switchBox
        },
        SearchForm: {
            searchForm: SearchForm.searchForm
        },
        Location: {
            autocomplete: SearchForm.location_autocomplete,
            switcher: SearchForm.switcher,
            input_departure: SearchForm.location_departure
        },
        Segment: {
            segment: SearchForm.segment,
            wrapper: SearchForm.segment__wrapper,
            cell_location: SearchForm.cell_location,
            input: SearchForm.input,
            input_focused: SearchForm.input_focused,
            cell_date: SearchForm.cell_dates
        },
        Segments: {
            segments: SearchForm.segments
        },
        Datepicker: {
            dates: SearchForm.dates,
            dates_focused: SearchForm.dates_focus,
            returnDelete: SearchForm.returnDelete,
            dates__to: cn(SearchForm.dates_to, Datepicker.dates__to),
            dates__icon: Datepicker.dates__icon,
            dates__back: SearchForm.dates_back,
            dates__to_focused: SearchForm.dates_to_focused,
            dates__back_focused: SearchForm.dates_back_focused,
            placeholder: SearchForm.dates_placeholder,
            alternative_dow: SearchForm.alternative_dow
        },
        Controls: {
            controls: SearchForm.controls
        },
        Passengers: {
            passengers: SearchForm.passengers,
            selector: SearchForm.passenger_selector,
            selector_focused: SearchForm.passenger_selector_open,
            arrowIcon: SearchForm.passengers_arrowIcon,
            title: SearchForm.passengers_title,
            mobileTitle: SearchForm.mobileTitle
        },
        StartSearch: {
            startSearch_iconMode: SearchForm.iconMode,
            startSearch: SearchForm.startSearch
        },
        Bottom: {
            bottom: SearchForm.bottom
        }
    },
    Passengers: {
        PassengerStyles: {
            decrease: SearchForm.passenger_decrease,
            increase: SearchForm.passenger_increase,
            passengerType: SearchForm.passengerType,
            age: SearchForm.age,
            counter: SearchForm.counter
        }
    },
    SearchFormDatepicker: {
        DayStyles: {
            day_withPrice: SearchFormDatepicker.day_withPrice,
            day_selected: SearchFormDatepicker.day_selected,
            day_startPeriod: SearchFormDatepicker.day_startPeriod,
            day_endPeriod: SearchFormDatepicker.day_endPeriod,
            day_inPeriod: SearchFormDatepicker.day_inPeriod,
            day_today: SearchFormDatepicker.day_today,
            day_hasPrice: SearchFormDatepicker.day_hasPrice,
            day__price: SearchFormDatepicker.day__price,
            day_endHover_withPrice: SearchFormDatepicker.day_endHover_withPrice,
            day_highlighted: SearchFormDatepicker.day_highlighted,
            day__inside: SearchFormDatepicker.day__inside
        },
        MonthHeaderStyles: {
            monthHeader__select_month: SearchFormDatepicker.monthHeader,
            monthHeader__select_year: SearchFormDatepicker.yearHeader
        },
        WeeklyHeaderStyles: {
            day: SearchFormDatepicker.weeklyHeader
        },
        SearchFormDatepickerStyles: {
            footer__done: SearchFormDatepicker.footer__done,
            footer__buttons: SearchFormDatepicker.footer__buttons,
            footer__clear: SearchFormDatepicker.footer__clear,
            dialog__activeDate: SearchFormDatepicker.dialog__activeDate
        }
    },
    OrderCard: {
        OrderCard: {
            root: OrderCard.root,
            container: OrderCard.container,
            type_account: OrderCard.type_account
        },
        OrderStatus: {
            icon: OrderStatus.icon,
            confirmed: OrderStatus.confirmed,
            root: OrderStatus.root,
            type_review: OrderStatus.type_review
        },
        OrderPassengers: {
            control: OrderPassengers.control
        },
        OrderChange: {
            button: OrderChange.button
        },
        OrderBooking: {
            root: OrderBooking.root,
            type_review: OrderBooking.type_review,
            orderCode: OrderBooking.orderCode,
            price: OrderBooking.price,
            security_code: OrderBooking.security_code,
            download: OrderBooking.download
        },
        CommonItem: {
            root: CommonItem.root,
            review: CommonItem.review,
            icon: CommonItem.icon,
            payload: cn(OrderStatus.payload),
            buttons: cn(OrderStatus.buttons)
        },
        OrderPreview: {
            root: OrderPreview.root,
            inner: OrderPreview.inner,
            city: OrderPreview.city,
            main_city: OrderPreview.main_city,
            period: OrderPreview.period,
            overlay: OrderPreview.overlay
        },
        PaymentStatus: {
            root: PaymentStatus.root,
            icon: PaymentStatus.icon
        }
    },
    Exchange: {
        CommonSelect: {
            header: CommonSelect.header,
            icon: CommonSelect.icon,
            button: CommonSelect.button,
            back: CommonSelect.back
        },
        Notice: {
            container: Notice.container,
            header: Notice.header
        },
        CreateRequest: {
            field: CreateRequest.field,
            submit: CreateRequest.submit,
            header: CreateRequest.header,
            button: CreateRequest.button,
            button_checked: CreateRequest.button_checked
        },
        Choice: {
            label: Choice.label,
            label_selected: Choice.label_selected,
            labelDate: Choice.labelDate,
            button: Choice.button,
            button_checked: Choice.button_checked
        },
        Calendar: {
            calendar: Calendar.calendar,
            day_highlight: Calendar.day_highlight,
            day_selected: Calendar.day_selected,
            day__inside: Calendar.day__inside
        },
        SelectedFlights: {
            price: SelectedFlights.price,
            charge: SelectedFlights.charge,
            charge__money: SelectedFlights.charge__money,
            total: SelectedFlights.total,
            total__money: SelectedFlights.total__money,
            header__name: SelectedFlights.header__name,
            change: SelectedFlights.change
        },
        Chip: {
            chip: Chip.chip,
            close: Chip.close
        },
        Inner: {
            header: Inner.header,
            header__text: Inner.header__text,
            closeIcon: Inner.closeIcon,
            container: Inner.container
        },
        RequestForm: {
            chip: RequestForm.chip
        },
        RequestControls: {
            button: RequestForm.button,
            refuse: RequestForm.refuse
        },
        Disclaimer: {
            wrapper: SearchFlights.disclaimer__wrapper
        },
        CommonContent: {
            serviceCard: CommonContent.serviceCard,
            passengerName: CommonContent.passengerName,
            name: CommonContent.name,
            price: CommonContent.price,
            totalRefund: CommonContent.totalRefund,
            serviceCard_meal: CommonContent.serviceCard_meal,
            serviceCard_seats: CommonContent.serviceCard_seats,
            serviceCard_baggage: CommonContent.serviceCard_baggage
        },
        Dialog: {
            paper: ExchangeDialog.paper,
            button: ExchangeDialog.button
        }
    },
    ServiceWithSegments: {
        ServiceWithSegments: {
            wrapper: ServiceWithSegments.wrapper
        }
    },
    BaggagePage: {
        BaggagePage: {
            baggage: BaggagePage.baggage,
            items: BaggagePage.items
        }
    },
    Refund: {
        StepTitle: {
            icon: StepTitle.icon
        },
        Passenger: {
            passenger: RefundPassenger.passenger,
            active: RefundPassenger.active,
            checkbox: RefundPassenger.checkbox,
            label: RefundPassenger.label
        },
        Passengers: {
            passenger: RefundPassenger.passenger,
            passengers: RefundPassenger.passengers
        },
        Reason: {
            active: Reason.active,
            label: Reason.label,
            radio: Reason.radio
        },
        ReasonForm: {
            groupTitle: ReasonForm.groupTitle
        },
        RefundPaper: {
            paper: cn(RefundPapper.paper, MobileStep.paper)
        },
        RequestForm: {
            formLabel: RequestForm.formLabel,
            agree: RequestForm.agree,
            control: RequestForm.control,
            checkbox: RequestForm.checkbox,
            active: RequestForm.active,
            comment: RequestForm.comment
        },
        RefundInfoModal: {
            button: RefundInfoModal.button,
            icon: RefundInfoModal.icon,
            content: RefundInfoModal.content,
            no: RefundInfoModal.no
        },
        Refund: {
            stepChoosed: Reason.stepChoosed,
            buttons: RefundPapper.buttons,
            prices: Refund.prices
        },
        MobileStep: {
            header: MobileStep.mobileStep,
            content: MobileStep.content
        },
        Wrapper: {
            title: RefundWrapper.title,
            wrapper: RefundWrapper.wrapper,
            header_wrapper: RefundWrapper.header_wrapper,
            header: RefundWrapper.header,
            close: RefundWrapper.close
        },
        StepsButtons: {
            button: RefundPapper.button
        },
        Price: {
            totalTitle: Refund.price__totalTitle,
            money: Refund.money,
            row: Refund.price__row
        },
        Ticket: {
            prices: RefundTicket.prices,
            info: RefundTicket.info,
            reject: Ticket.reject,
            header: RefundTicket.header,
            ticket: RefundTicket.ticket,
            title: RefundTicket.title,
            time: RefundTicket.time,
            status: RefundTicket.status,
            button: RefundTicket.button,
            buttons: RefundTicket.buttons
        },
        Status: {
            waiting: RefundTicket.waiting,
            processing: RefundTicket.processing,
            rejected: RefundTicket.rejected
        }
    },
    Checkin: {
        Inital: {
            wrapper: Checkin.initial_wrapper,
            hint: Checkin.initial_hint,
            form: Checkin.initial_form,
            hint__image: Checkin.initial_hint__image,
            hint__title: Checkin.initial_hint__title,
            hint__info__icon: Checkin.initial_hint__info__icon,
            hint__info__text: Checkin.initial_hint__info__text
        },
        Passengers: {
            segments: Checkin.segments,
            passengers: Checkin.passengers,
            toolbar__button: Checkin.toolbar__button
        }
    },
    CheckinRules: {
        CheckinRules: {
            rules__button: Checkin.rules__button
        }
    },
    RetrieveBooking: {
        RetrieveBooking: {
            title: RetrieveBooking.title,
            title__icon: RetrieveBooking.title__icon,
            form__row: RetrieveBooking.form__row,
            button: RetrieveBooking.button,
            button_disabled: RetrieveBooking.button_disabled
        }
    },
    Input: {
        Input: {
            input: Input.input,
            disabled: Input.disabled,
            input_filled: Input.input_filled,
            input_focused: Input.input_focused,
            input_outline: Input.input_outline,
            textField_focused: Input.textField_focused,
            textField__root: Input.textField__root,
            real_input: Input.real_input,
            label_error: Input.label_error,
            hint_error: Input.hint_error,
            hint: Input.hint,
            input_error: Input.input_error,
            input_standard: Input.input_standard
        }
    },
    Segment: {
        SegmentCommon: {
            wrapper: SegmentCommon.wrapper,
            header: SegmentCommon.header,
            city__airport: SegmentCommon.city__airport,
            flightInfo__title: SegmentCommon.flightInfo__title,
            cities: SegmentCommon.cities,
            flightInfo__item: SegmentCommon.flightInfo__item,
            plane__icon: SegmentCommon.planeIcon,
            airline__name: SegmentCommon.airline__name,
            city__info: SegmentCommon.city__info,
            flightInfo__value: SegmentCommon.flightInfo__value,
            footer__item: SegmentCommon.footer__item,
            airline__logo: SegmentCommon.airline__logo,
            notAvailable: SegmentCommon.notAvailable
        }
    },
    SelectedPassenger: {
        SelectedPassenger: {
            passenger__name: SelectedPassenger.passenger__name,
            passengerInfo__field: SelectedPassenger.passengerInfo__field
        },
        LoyaltyNumber: {
            button: SelectedPassenger.loayltyNumber__button
        }
    },
    AddPassenger: {
        AddPassenger: {
            addButton__icon: AddPassenger.icon,
            wrapper: AddPassenger.wrapper,
            addButton: AddPassenger.addButton,
            submitButton: AddPassenger.submitButton,
            form__title: AddPassenger.form__title,
            form: AddPassenger.form
        }
    },
    PaymentForm: {
        PaymentForm: {
            wrapper: PaymentForm.wrapper,
            details: PaymentForm.details,
            content__title: PaymentForm.content__title,
            oneMethod: PaymentForm.oneMethod,
            content: PaymentForm.content,
            details__button: PaymentForm.details__button,
            details__total: PaymentForm.details__total,
            sbp: PaymentForm.sbp,
            sberpay: PaymentForm.sberpay,
            cards_header: PaymentForm.cards_header,
            googlepay: PaymentForm.googlepay,
            applepay: PaymentForm.applepay
        },
        Summary: {
            header: PaymentForm.summary__header
        },
        Gateway: {
            selector: PaymentForm.gateway__selector,
            header__arrow: PaymentForm.gateway__header__arrow,
            arrow: PaymentForm.gateway__arrow
        },
        Card: {
            iframe: PaymentForm.iframe
        }
    },
    Notification: {
        Notification: {
            wrapper: Notification.wrapper,
            context: Notification.context,
            icon: Notification.icon,
            text: Notification.text
        }
    },
    AdditionalServiceCard: {
        AdditionalServiceStyles: {
            buttonBase: AdditionalServiceCard.buttonBase
        }
    },
    AdditionalServices: {
        Insurance: {
            paper: AdditionalServicesInsurance.paper
        }
    },
    ResendTicketsForm: {
        ResendTicketsForm: {
            wrapper: ResendTicketsForm.wrapper,
            close: ResendTicketsForm.close
        }
    },
    CheckoutAdditionalService: {
        CheckoutAdditionalService: {
            header: CheckoutAdditionalService.header,
            header__text: cn(CheckoutAdditionalService.header__text, Insurance.header__text),
            add: CheckoutAdditionalService.add,
            buttons: CheckoutAdditionalService.buttons,
            icon: CheckoutAdditionalService.icon,
            main: CheckoutAdditionalService.main,
            content: CheckoutAdditionalService.content,
            header__price: CheckoutAdditionalService.header__price,
            service_active: CheckoutAdditionalService.service_active,
            service: CheckoutAdditionalService.service,
            list__cities: CheckoutAdditionalService.list__cities
        },
        Animal: {
            container: cn(Animal.container, CheckoutAdditionalServiceAnimal.container)
        },
        Seats: {
            seats: Seats.seats
        },
        CheckoutAdditionalServiceMeal: {
            meal: CheckoutAdditionalServiceMeal.meal
        },
        CheckoutAdditionalServiceBaggage: {
            baggage: CheckoutAdditionalServiceBaggage.baggage
        },
        Insurance: {
            insurance: Insurance.insurance
        },
        Aeroexpress: {
            aeroexpress: Aeroexpress.aeroexpress
        },
        AeroexpressPopup: {
            content: Aeroexpress.content,
            header: Aeroexpress.header
        },
        AeroexpressSegment: {
            header: Aeroexpress.header
        },
        PriorityBoarding: {
            priority: PriorityBoarding.priority
        },
        PetPopup: {
            header: PetPopup.header
        },
        Business: {
            business: Business.business,
            header: Business.header,
            paper: Business.paper
        },
        CapsuleHotel: {
            capsuleHotel: CapsuleHotel.capsuleHotel
        },
        Loyalty: {
            loyalty: CheckoutAdditionalServiceLoyalty.loyalty
        },
        BaggageDelivery: {
            baggageDelivery: BaggageDelivery.baggageDelivery,
            popup: BaggageDelivery.popup
        },
        UpgradeFlightCLassCheckoutService: {
            upgradeFlightClass: CheckoutAdditionalServiceUpgradeFlightClass.card
        }
    },
    ServiceSummary: {
        ServiceSummaryOption: {
            option__price_icon: ServiceSummary.option__price_icon
        }
    },
    SegmentTabs: {
        SegmentTabs: {
            wrapper_center: SegmentTabs.wrapper_center,
            segments: SegmentTabs.segments,
            title: SegmentTabs.title,
            wrapper: SegmentTabs.wrapper,
            right: cn(Meal.segmentTabs__right),
            inner: SegmentTabs.inner
        },
        SegmentTab: {
            segment: SegmentTabs.segment,
            checked: SegmentTab.checked
        }
    },
    IncludedBaggage: {
        IncludedBaggage: {
            root: IncludedBaggage.root,
            header: IncludedBaggage.header
        }
    },
    AdditionalBaggage: {
        AdditionalBaggage: {
            root: AdditionalBaggage.root,
            header: AdditionalBaggage.header
        },
        PromoMessage: {
            root: AdditionalBaggage.PromoMessage__root
        }
    },
    PromoCode: {
        PromoCode: {
            clearWrapper: PromoCode.clearWrapper,
            promoCode: PromoCode.promoCode,
            icon: PromoCode.icon,
            clear: PromoCode.clear,
            trigger: PromoCode.trigger,
            icon_notApplied: PromoCode.icon_notApplied,
            contentWrp: PromoCode.contentWrp
        }
    },
    BaggageCard: {
        BaggageCard: {
            wrapper: BaggageCard.wrapper,
            icon: cn(BaggageCard.icon, SpecialEquipment.baggage__icon),
            title: BaggageCard.title,
            description: BaggageCard.description,
            price: BaggageCard.price,
            button: BaggageCard.button,
            button_edit: BaggageCard.button_edit
        }
    },
    Baggage: {
        BaggageIcon: {
            size: BaggageIcon.size,
            checked_baggage: BaggageIcon.checked_baggage,
            small: BaggageIcon.small,
            root: BaggageIcon.root,
            large: BaggageIcon.large,
            carry_on: BaggageIcon.carry_on,
            middle: BaggageIcon.middle,
            measurement: BaggageIcon.measurement,
            wrapper: BaggageIcon.wrapper,
            value: BaggageIcon.value
        },
        Baggage: {
            name: Baggage.name
        }
    },
    OverweightBaggage: {
        OverweightBaggage: {
            flight_direction: cn(Baggage.flight_direction, SpecialEquipment.flight_direction),
            flight_icon: Baggage.flight_icon,
            flight_count: cn(Baggage.flight_count, SpecialEquipment.flight_count)
        }
    },
    BaggageList: {
        BaggageList: {
            item: BaggageList.item,
            counts: BaggageList.counts
        },
        BaggageCount: {
            root: BaggageList.root
        },
        BaggageDirection: {
            root: BaggageList.direction__root
        }
    },
    BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            price: BaggageTotalPriceBlock.price,
            control: BaggageTotalPriceBlock.control,
            control__edit: BaggageTotalPriceBlock.control__edit
        }
    },
    BaggageCounter: {
        BaggageCounter: {
            plus: UpgradeBaggageDialog.baggage_plus,
            minus: UpgradeBaggageDialog.baggage_minus
        }
    },
    UpgradeBaggageDialog: {
        HeaderEquipmentItem: {
            wrapper: UpgradeBaggageDialog.wrapper,
            selected: UpgradeBaggageDialog.selected,
            active: UpgradeBaggageDialog.active
        },
        BaggageDialogHeader: {
            row: UpgradeBaggageDialog.row,
            category_title: UpgradeBaggageDialog.category_title,
            category_description: UpgradeBaggageDialog.category_description,
            baggage_wrapper: UpgradeBaggageDialog.baggage_wrapper,
            price_label: UpgradeBaggageDialog.price_label,
            label: UpgradeBaggageDialog.label,
            equipment: UpgradeBaggageDialog.equipment
        },
        BaggagePassenger: {
            checkbox_root: UpgradeBaggageDialog.checkbox_root,
            checkbox_rootChecked: UpgradeBaggageDialog.checkbox_rootChecked,
            number: UpgradeBaggageDialog.passenger_number,
            name: UpgradeBaggageDialog.passenger_name
        },
        BaggageFooter: {
            cancel_btn: UpgradeBaggageDialog.cancel_btn,
            confirm_btn: UpgradeBaggageDialog.confirm_btn,
            clear: UpgradeBaggageDialog.clear,
            price_wrapper: UpgradeBaggageDialog.price_wrapper
        },
        BaggageSegment: {
            root: UpgradeBaggageDialog.root,
            name: UpgradeBaggageDialog.segment__name,
            price: UpgradeBaggageDialog.segment__price
        },
        MobileBaggageSelector: {
            selector: UpgradeBaggageDialog.selector,
            selected: UpgradeBaggageDialog.selector_selected,
            footer: UpgradeBaggageDialog.selector__footer,
            price: UpgradeBaggageDialog.selector__price,
            root: UpgradeBaggageDialog.root,
            container: UpgradeBaggageDialog.container,
            description: UpgradeBaggageDialog.mobileBaggageSelectorDescription,
            BaggageExcess: UpgradeBaggageDialog.BaggageExcess
        },
        UpgradeBaggageDialog: {
            modalHeader: UpgradeBaggageDialog.modalHeader,
            root: UpgradeBaggageDialog.root
        }
    },
    SpecialEquipment: {
        SpecialEquipment: {
            flight_count: SpecialEquipment.flight_count,
            flight_direction: SpecialEquipment.flight_direction,
            card: SpecialEquipment.card
        }
    },
    AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            wrapper: AdditionalBaggage.service_banner
        }
    },
    Switch: {
        Switch: {
            wrapper: Switch.wrapper,
            active: Switch.active
        },
        SwitchV2: {
            active: SwitchV2.active,
            primary: SwitchV2.primary
        }
    },
    SlideBottom: {
        SlideBottomStyles: {
            header: SlideBottom.header,
            container: SlideBottom.container
        }
    },
    SeatMap: {
        AirplaneStyles: {
            airplane: Airplane.airplane,
            details: Airplane.details,
            airplane__name: Airplane.airplane__name,
            airplane__image: Airplane.airplane__image,
            airplane__info: Airplane.airplane__info,
            segment: Airplane.segment,
            seatsDetails: Airplane.seatsDetails
        },
        AirplaneInfoStyles: {
            sunInfo: AirplaneInfo.sunInfo
        },
        PassengersStyles: {
            recommend: Passengers.recommend,
            recommend__icon: Passengers.recommend__icon,
            recommend__label: Passengers.recommend__label,
            together: Passengers.together,
            recommend__price: Passengers.recommend__price,
            passengers__button: Passengers.passengers__button,
            passengers__button_clear: Passengers.passengers__button_clear,
            recommend__wrapper: Passengers.recommend__wrapper
        },
        Passenger: {
            item: Passenger.item,
            item_active: Passenger.item_active,
            item_recommend: Passenger.item_recommend,
            passengerName: Passenger.passengerName,
            avatar: Passenger.avatar,
            payload: Passenger.payload,
            money: Passenger.money,
            select: Passenger.select,
            select_selected: Passenger.select_selected,
            seat: Passenger.seat
        },
        SeatMapDisclaimerStyles: {
            disclaimer: Disclaimer.disclaimer
        },
        Controls: {
            button: Controls.button,
            back: Controls.back
        },
        SeatsInfoStyles: {
            classInfo: SeatsInfo.classInfo,
            classInfo__payload: SeatsInfo.classInfo__payload,
            title: SeatsInfo.title,
            description: SeatsInfo.description,
            seat__info: SeatsInfo.seat__info,
            seat_economy: SeatsInfo.seat_economy,
            seat_business: SeatsInfo.seat_business,
            seat_preferred: SeatsInfo.seat_preferred,
            feature: SeatsInfo.feature,
            price__wrapper: SeatsInfo.price__wrapper,
            price: SeatsInfo.price,
            seat: SeatsInfo.seat,
            image: SeatsInfo.image,
            amenities__item: SeatsInfo.amenities__item,
            header: SeatsInfo.header
        },
        SeatStyles: {
            seat: Seat.seat,
            number: Seat.number,
            seat_recommend: Seat.seat_recommend,
            seat_recommendCurrent: Seat.seat_recommendCurrent,
            seat_business: Seat.seat_business,
            seat_occupied: Seat.seat_occupied,
            seat_unavailable: Seat.seat_unavailable,
            number_name: Seat.number_name
        },
        SeatMapStyles: {
            seatMap__wrapper: SeatMap.seatMap__wrapper,
            seatMap: SeatMap.seatMap,
            row__serviceHeader__wrapper: SeatMap.row__serviceHeader__wrapper,
            wings: SeatMap.wings,
            exit__label: SeatMap.exit__label
        },
        SeatTooltipStyles: {
            tooltip: SeatTooltip.tooltip,
            tooltip_withInfo: SeatTooltip.tooltip_withInfo,
            info: SeatTooltip.info,
            details__seatName: SeatTooltip.details__seatName,
            number: SeatTooltip.number
        },
        SeatServiceSlide: {
            single: SeatsInfo.seatService__button,
            serviceInfo__slider: SeatsInfo.serviceInfo__slider
        },
        SeatsRecommendPopup: {
            control: SeatsInfo.control,
            control_selected: SeatsInfo.control_selected,
            controls: SeatsInfo.controls,
            price__wrapper: SeatsInfo.price__wrapper,
            money: SeatsInfo.money,
            content: cn(SeatsInfo.recommend__content, SlideBottom.recommend__content),
            main: SeatsInfo.main,
            header: SeatsInfo.recommend__header,
            main__icon: SeatsInfo.main__icon,
            cancel: SeatsInfo.recommend__cancel,
            confirm: SeatsInfo.recommend__confirm,
            slide: SeatsInfo.slide
        },
        ComponentStyles: {
            unavailable: SeatMapComponent.unavailable,
            unavailable__img: SeatMapComponent.unavailable__img
        }
    },
    Amenities: {
        Amenities: {
            amenities__item: Amenities.amenities__item,
            amenities__item_unavailable: Amenities.amenities__item_unavailable,
            amenities: Amenities.amenities
        }
    },
    Results: {
        OtherDates: {
            container: OtherDates.container,
            text: OtherDates.text,
            suggestions: OtherDates.suggestions
        },
        Calendar: {
            title__text: Calendar.title__text,
            title__cities: Calendar.title__cities,
            title: Calendar.title,
            legNumber: Calendar.legnumber,
            diagram: Calendar.diagram
        },
        Sortings: {
            title: Sortings.title,
            sortBy: Sortings.sortBy,
            currentSorting: Sortings.currentSorting
        },
        Results: {
            header__stepbar: Results.stepbar,
            inner: Results.inner,
            toolbar__backButton: Toolbar.backButton,
            toolbar__nextButton: Toolbar.nextButton,
            results_withPromocodes: Results.results_withPromocodes
        },
        Disclaimer: {
            wrapper: Results.disclaimer_wrapper
        },
        FlightsList: {
            controls__wrapper: MobileFlight.controls__wrapper,
            leg: Results.leg
        },
        SelectedFares: {
            wrapper: SelectedFares.wrapper,
            title: SelectedFares.title,
            selectedContentClassName: SelectedFares.selectedContentClassName
        }
    },
    FareLock: {
        DescriptionBlock: {
            root: FareLock.description_block,
            icon: FareLock.description_block__icon,
            title: FareLock.description_block__title,
            description: FareLock.description_block__description,
            rules: FareLock.description_block__rules,
            confirmedIcon: FareLock.description_block__confirmedIcon
        },
        FareLock: {
            switch_block: FareLock.switch_block,
            root: FareLock.root,
            root_confirmed: FareLock.root_confirmed
        },
        SwitchBlock: {
            switch_active: FareLock.switch_active,
            root: FareLock.switchBlock__root,
            price_label: FareLock.switchBlock__priceLabel,
            price_wrapper: FareLock.switchBlock__priceWrapper,
            switch_label: FareLock.switchBlock__switchLabel,
            switch_control: FareLock.switchBlock__switchControl,
            switch_wrapper: FareLock.switchBlock__switchWrapper
        }
    },
    WeekCalendar: {
        WeekCalendarStyles: {
            dayWrapper: WeekCalendar.dayWrapper,
            dayWrapper_selected: WeekCalendar.wrapper__selected
        },
        DayStyles: {
            day: WeekCalendar.day,
            day_best: WeekCalendar.day_best,
            date: WeekCalendar.date,
            date__dow: WeekCalendar.date__dow,
            date__info: WeekCalendar.date__info,
            price__wrapper: WeekCalendar.price__wrapper,
            price: WeekCalendar.price
        },
        SliderStyles: {
            arrow_next: WeekCalendar.arrow_next,
            arrow_prev: WeekCalendar.arrow_prev
        }
    },
    DesktopFlightRoute: {
        RouteArrowStyles: {
            container: DesktopFlightRoute.container,
            circle: DesktopFlightRoute.circle,
            circle_right: DesktopFlightRoute.circle_right,
            route: DesktopFlightRoute.route,
            flightNumber: DesktopFlightRoute.flightNumber,
            timeInRoute: DesktopFlightRoute.timeInRoute,
            stop: DesktopFlightRoute.stop
        },
        SegmentStyles: {
            flightInfo: DesktopFlightRoute.flightInfo,
            flightInfo__container: DesktopFlightRoute.flightInfo__container,
            flightInfo__icon: DesktopFlightRoute.flightInfo__icon,
            aircraft: DesktopFlightRoute.aircraft,
            totalDuration: DesktopFlightRoute.totalDuration,
            flightInfo__date: DesktopFlightRoute.flightInfo__date,
            flightInfo__airline: DesktopFlightRoute.flightInfo__airline,
            planeInfo: DesktopFlightRoute.planeInfo
        },
        PointStyles: {
            date__container: DesktopFlightRoute.date__container,
            date: DesktopFlightRoute.point__date,
            point: DesktopFlightRoute.point,
            time: DesktopFlightRoute.time,
            point_arrival: DesktopFlightRoute.point_arrival,
            airport: DesktopFlightRoute.airport
        },
        FlightPriceStyles: {
            button: DesktopFlightRoute.Button,
            selectFrom_best: DesktopFlightRoute.selectFrom,
            inlineFares: DesktopFlightRoute.inlineFares,
            fare_family: DesktopFlightRoute.fare_family,
            price__money: DesktopFlightRoute.price__money,
            price: DesktopFlightRoute.price,
            price_selected: DesktopFlightRoute.price_selected,
            best: DesktopFlightRoute.best,
            price_original: DesktopFlightRoute.price_original,
            price__money_withPromoCode: DesktopFlightRoute.price__money_withPromoCode,
            seats: DesktopFlightRoute.flightPrice__seats
        },
        StopsInfoStyles: {
            stop: DesktopFlightRoute.stop
        },
        DesktopFlightRouteStyles: {
            flight: DesktopFlightRoute.flight,
            flight_dropdown: DesktopFlightRoute.flight_dropdown,
            selected: DesktopFlightRoute.flight_selected
        }
    },
    FareGroup: {
        FareGroup: {
            fareGroup: FareGroup.fareGroup,
            button: FareGroup.Button,
            option__title: FareGroup.option__title,
            option__value: FareGroup.option__value,
            option_unavailable: FareGroup.option_unavailable,
            price: cn(FareGroup.fareGroup__price, FareGroup.price),
            option_free: FareGroup.option_free,
            fareGroup_white: FareGroup.fareGroup_white,
            keyFeature: FareGroup.keyFeature,
            keyFeature__icon: FareGroup.keyFeature__icon,
            fareGroup_Business: FareGroup.fareGroup_Business,
            options: FareGroup.options
        },
        FareGroupGrid: {
            title: FareGroup.title,
            small: FareGroup.small,
            recommended: FareGroup.recommended,
            header: FareGroup.header,
            paid: FareGroup.paid,
            option: FareGroup.option,
            upgradeOffer: FareGroup.upgradeOffer,
            size: FareGroup.size,
            icon: FareGroup.icon,
            availabilityIcon: FareGroup.availabilityIcon,
            not_available: FareGroup.not_available
        },
        RecommendedBadge: {
            badge: FareGroup.badge
        },
        FareGroupIcon: {
            container: cn(FareGroup.icon, FareGroup.icon_container)
        }
    },
    Pagination: {
        Pagination: {
            item: FareGroup.pagination__item,
            item_active: FareGroup.pagination__item_active
        }
    },
    FlightCard: {
        FlightCard: {
            aircraft__name_active: FlightCard.aircraft__name_active,
            airline__number: FlightCard.airline__number,
            aircraft__name: FlightCard.aircraft__name,
            airline__name: FlightCard.airline__name,
            flight: FlightCard.flight,
            info: FlightCard.info,
            amenities: FlightCard.amenities
        }
    },
    DesktopFlight: {
        DesktopFlightModalHeader: {
            close__icon: DesktopFlight.close__icon
        },
        FlightInfoSegment: {
            isMobile: DesktopFlight.isMobile,
            location: DesktopFlight.location,
            locations: DesktopFlight.locations,
            flight: DesktopFlight.flight,
            transfer: cn(DesktopFlight.transfer, SelectedFares.transfer)
        },
        Location: {
            city: Location.city,
            terminal: Location.terminal,
            date: Location.date,
            date_icon: Location.date_icon,
            time: Location.time,
            isMobile: Location.isMobile,
            time_iata: Location.time_iata,
            weather: Location.weather,
            airport: Location.airport
        },
        ModalContent: {
            paper_inline: DesktopFlight.paper_inline,
            buttons: cn(DesktopFlight.buttons, FareGroup.buttons, DesktopFlightModalContent.buttons),
            content_wrapper: DesktopFlight.content_wrapper,
            content: DesktopFlight.content,
            slider: DesktopFlight.slider,
            pagination__index: DesktopFlight.pagination__index,
            slider__wrp: DesktopFlight.slider__wrp,
            fareGroup__button: cn(FareGroup.fareGroup__button, DesktopFlightModalContent.fareGroup__button),
            fareGroup__button_business: FareGroup.fareGroup__button_business,
            fareGroup__button_active: FareGroup.fareGroup__button_active,
            segments: DesktopFlightModalContent.segments,
            paper: DesktopFlightModalContent.paper,
            scroll_up: DesktopFlightModalContent.scroll_up,
            total_time: DesktopFlightModalContent.total_time
        },
        SelectedFareGroup: {
            button: SelectedFareGroup.button,
            button__price: SelectedFareGroup.button__price,
            button__rules: SelectedFareGroup.button__rules,
            option: SelectedFareGroup.option,
            fare: SelectedFareGroup.fare,
            fareConditions__closeButton: SelectedFareGroup.fareConditions__closeButton
        },
        DesktopFlightModalContentSelected: {
            segments: cn(SelectedFares.selectedModal__segments),
            segment__card: DesktopFlightSelected.segment__card,
            segment__flight: cn(SelectedFares.selectedModal__segmentFlight),
            time: cn(DesktopFlightSelected.time, SelectedFares.selectedModal__time),
            container_dropdown: DesktopFlightSelected.container_dropdown
        }
    },
    MobileFlight: {
        FlightStyles: {
            price__money: MobileFlight.money,
            price: DesktopFlightRoute.Button,
            flight: cn(MobileFlightStyles.flight, MobileFlight.flight),
            flight_selected: MobileFlightStyles.flight_selected,
            readonly: MobileFlightStyles.readonly,
            segments: MobileFlightStyles.segments,
            selectedFare: cn(MobileFlightStyles.selectedFare, MobileFlight.selectedFare),
            price__text: MobileFlight.price__text,
            selectedFare__price: MobileFlightStyles.selectedFare__price,
            price_originalMoney: DesktopFlightRoute.price_original
        }
    },
    LocationAutocomplete: {
        MobileDialog: {
            input: MobileDialog.input,
            hint: MobileDialog.hint,
            closer: MobileDialog.closer
        },
        Group: {
            group__label: MobileDialog.group__label
        },
        Option: {
            label: MobileDialog.label,
            code: MobileDialog.code
        }
    },
    CompareFares: {
        CompareFares: {
            fareHeadingItem_isKey: CompareFares.fareHeadingItem_isKey,
            fareHeadingItem: CompareFares.fareHeadingItem,
            fareHeadingItem__title: CompareFares.fareHeadingItem__title,
            title: CompareFares.title,
            tableRow: CompareFares.tableRow,
            availabilityIcon: CompareFares.availabilityIcon,
            fareHeadingItem__serviceClass: CompareFares.fareHeadingItem__serviceClass,
            col: CompareFares.col,
            baggage: CompareFares.baggage,
            ticketChanges: CompareFares.ticketChanges,
            services: CompareFares.services,
            groupTitle: CompareFares.groupTitle,
            headingCell: CompareFares.headingCell,
            image: CompareFares.image,
            headingWrp: CompareFares.headingWrp,
            cell: CompareFares.cell,
            compareFares: CompareFares.compareFares,
            content: CompareFares.content,
            slider: CompareFares.slider,
            link: CompareFares.link
        }
    },
    FareConditions: {
        FareConditions: {
            segmentTabsWrapper: FareConditions.segmentTabsWrapper,
            segmentTabsInner: FareConditions.segmentTabsInner,
            segmentTabsSegments: FareConditions.segmentTabsSegments,
            segmentTabsTab: FareConditions.segmentTabsTab,
            segmentTabsTab__active: FareConditions.segmentTabsTab__active,
            fareConditionsContent: FareConditions.fareConditionsContent,
            tabsWrp: FareConditions.tabsWrp
        }
    },
    Modal: {
        Modal: {
            closeIcon: Modal.closeIcon,
            modal: Modal.modal
        }
    },
    Stepbar: {
        Stepbar: {
            stepbar: Stepbar.stepbar,
            content: Stepbar.content,
            cart: Stepbar.cart
        },
        Step: {
            step: Stepbar.step,
            step_passed: Stepbar.passed,
            id: Stepbar.id,
            step_active: Stepbar.active
        }
    },
    QuickSearchForm: {
        Summary: {
            trigger__arrow: QuickSearchForm.trigger__arrow,
            route: QuickSearchForm.route,
            passengers: QuickSearchForm.route,
            trigger: cn(QuickSearchForm.route, QuickSearchForm.trigger)
        },
        QuickSearchForm: {
            promoCode__wrp: QuickSearchForm.promoCode__wrp,
            inner: QuickSearchForm.inner,
            wrapper: QuickSearchForm.wrapper,
            form: QuickSearchForm.form,
            promoCode: QuickSearchForm.promoCode,
            promoCode__icon: QuickSearchForm.promoCode__icon,
            promoCode__applied: QuickSearchForm.promoCode__applied
        }
    },
    Toolbar: {
        Toolbar: {
            toolbar: Toolbar.toolbar,
            button: Toolbar.button,
            price: Toolbar.price,
            actions: Toolbar.actions,
            inner: Toolbar.inner,
            backButton__wrapper: Toolbar.backButton__wrapper
        }
    },
    Checkout: {
        CheckoutStyles: {
            container: Checkout.container,
            gdsTotalPrice: Checkout.gdsTotalPrice,
            form: Checkout.form,
            messages: Checkout.messages,
            summary__wrp: Checkout.summary__wrp,
            summary: Checkout.summary,
            selectedFlight__container: Checkout.selectedFlight__container,
            selectedFlight__header: Checkout.selectedFlight__header,
            checkoutServices: Checkout.checkoutServices
        },
        ThanksBanner: {
            wrapper: ThanksBanner.wrapper,
            title: ThanksBanner.title
        },
        FlightNotAvailable: {
            buttons: FlightNotAvailable.buttons
        }
    },
    Contacts: {
        ContactsStyles: {
            footer__slideBottom: Checkout.contacts__footer__sliderBottom,
            footer: Checkout.contacts__footer,
            contacts: Contacts.contacts,
            description__header: Contacts.description__header,
            description__text: Contacts.description__text,
            footer_login: Contacts.footer_login,
            footer__inner: Contacts.footer__inner,
            footer__text: Contacts.footer__text
        }
    },
    PassengerForm: {
        PopupWrapper: {
            disabled: PassengerForm.disabled,
            confirm: PassengerForm.confirm
        },
        PassengerForm: {
            form: PassengerForm.form
        },
        Header: {
            header: PassengerFormHeader.header,
            number: PassengerFormHeader.number,
            passengerName: PassengerFormHeader.passengerName,
            filled: PassengerFormHeader.filled
        },
        LoyaltyGroup: {
            loyalty_switch: PassengerFormGroup.loyalty_switch
        },
        Group: {
            container: PassengerFormGroup.container,
            field_birthdate: PassengerFormGroup.field_birthdate
        },
        Disabilities: {
            switchControl__errorText: PassengerForm.disabilities__switchControlErrorText
        }
    },
    Passenger: {
        Switch: {
            element: PassengerSwitch.element,
            element_active: PassengerSwitch.element_active,
            element_error: PassengerSwitch.element_error,
            error: PassengerSwitch.error
        },
        Select: {
            input: Select.input,
            input_standard: Select.input_standard,
            helperText: Select.helperText
        }
    },
    SuggestionModalContent: {
        SuggestionModalContent: {
            modal__text: SuggestionModalContent.modal__text,
            modal__button: SuggestionModalContent.modal__button,
            modal__button_decline: SuggestionModalContent.modal__button_decline,
            modal__content: SuggestionModalContent.modal__content
        }
    },
    DesktopSummaryFlight: {
        SummaryFlight: {
            flight: SummaryFlight.flight,
            features: SummaryFlight.features,
            feature__icon: SummaryFlight.feature__icon,
            feature__text: SummaryFlight.feature__text,
            features__links: SummaryFlight.features__links,
            info: SummaryFlight.info
        },
        Header: {
            header__cell: DesktopSummaryFlightHeader.header__cell,
            header__icon: DesktopSummaryFlightHeader.header__icon,
            title: DesktopSummaryFlightHeader.title,
            travelTime: DesktopSummaryFlightHeader.travelTime,
            fareGroup__logo: DesktopSummaryFlightHeader.fareGroup__logo
        }
    },
    DesktopFlightInfo: {
        DesktopFlightInfo: {
            footer: DesktopFlightInfo.footer,
            button_default: DesktopFlightInfo.button_default
        },
        Segment: {
            logo: DesktopFlightInfoSegment.logo,
            duration: DesktopFlightInfoSegment.duration,
            aircraft__name__link: DesktopFlightInfoSegment.aircraft__name__link
        },
        Point: {
            point: DesktopFlightInfoPoint.point
        }
    },
    Service: {
        DialogStyles: {
            popupHeader: DialogStyles.popupHeader
        }
    },
    PassengersDetails: {
        PassengersDetails: {
            number: PassengersDetailsPassenger.number
        },
        Passenger: {
            passenger: PassengersDetailsPassenger.passenger,
            filled: PassengersDetailsPassenger.filled
        }
    },
    AircraftPopup: {
        AircraftPopupContent: {
            slider: AircraftPopupContent.slider
        }
    },
    AccountModule: {
        Account: {
            loginWrapper: AccountModuleAccount.loginWrapper,
            active__route: AccountModuleAccount.active__route,
            route__title: AccountModuleAccount.route__title
        }
    },
    LoginPage: {
        Form: {
            wrapper: LoginPageForm.wrapper,
            loginForm: LoginPageForm.loginForm,
            loginForm__button: LoginPageForm.loginForm__button,
            social: LoginPageForm.social,
            social__text: LoginPageForm.social__text,
            social__buttons: LoginPageForm.social__buttons,
            footer: LoginPageForm.footer,
            alternative: LoginPageForm.alternative
        },
        Header: {
            header: LoginPageHeader.header,
            title: LoginPageHeader.title,
            buttons: LoginPageHeader.buttons,
            prevButton: LoginPageHeader.prevButton,
            step: LoginPageHeader.step
        },
        Verification: {
            LoginVerification__form: LoginPageVerification.LoginVerification__form,
            LoginVerification__text: LoginPageVerification.LoginVerification__text,
            submit_button: LoginPageVerification.submit_button,
            footer: LoginPageVerification.footer,
            loader: LoginPageVerification.loader,
            success: LoginPageVerification.success
        },
        Register: {
            content: LoginPageRegister.content,
            button: LoginPageRegister.button,
            actions: LoginPageRegister.actions,
            label: LoginPageRegister.label,
            link: LoginPageRegister.link
        },
        Welcome: {
            welcomeMsg: LoginPageWelcome.welcomeMsg,
            descriptionMsg: LoginPageWelcome.descriptionMsg
        }
    },
    Timer: {
        Timer: {
            timer: Timer.timer,
            link: Timer.link
        }
    },
    Sidebar: {
        MobileMenu: {
            wrapper: MobileMenu.wrapper,
            title: MobileMenu.title,
            button: MobileMenu.button,
            button_opened: MobileMenu.button_opened
        }
    },
    Traveller: {
        Traveller: {
            wrapper: Traveller.wrapper,
            header: Traveller.header
        }
    },
    TravellerForm: {
        TravellerForm: {
            row: TravellerForm.row,
            button__delete: TravellerForm.button__delete
        }
    },
    Loyalty: {
        LoyaltyConnect: {
            loyaltyConnect: LoyaltyConnect.loyaltyConnect,
            header: LoyaltyConnect.header,
            group: LoyaltyConnect.group,
            group__field: LoyaltyConnect.group__field,
            actions: LoyaltyConnect.actions,
            buttons__wrapper: LoyaltyConnect.buttons__wrapper,
            button: LoyaltyConnect.button
        },
        LoyaltyStub: {
            card__description: LoyaltyStub.card__description,
            content__description: LoyaltyStub.content__description,
            buttons: LoyaltyStub.buttons,
            miles__link: LoyaltyStub.miles__link
        },
        ForgotPassword: {
            wrapper: ForgotPassword.wrapper,
            header: ForgotPassword.header,
            step: ForgotPassword.step,
            controls: ForgotPassword.controls,
            controls__left: ForgotPassword.controls__left
        }
    },
    TransferInfo: {
        TransferInfo: {
            transfer__layover: TransferInfo.transfer__layover,
            transfer: TransferInfo.transfer
        }
    },
    MobileStepbar: {
        StepStyles: {
            step: MobileStepbarStep.step,
            step_disabled: MobileStepbarStep.step_disabled,
            name__index: MobileStepbarStep.name__index,
            name__label: MobileStepbarStep.name__label,
            step__price: MobileStepbarStep.step__price
        },
        StepContentStyles: {
            header__text: MobileStepbarStepContent.header__text,
            content__icon: MobileStepbarStepContent.content__icon,
            content__item: MobileStepbarStepContent.content__item,
            segment__header: MobileStepbarStepContent.segment__header,
            segment__header_flights: MobileStepbarStepContent.segment__header_flights,
            item__row: MobileStepbarStepContent.item__row,
            price__money: MobileStepbarStepContent.price__money,
            passenger__seat: MobileStepbarStepContent.passenger__seat,
            baggage__weight: MobileStepbarStepContent.baggage__weight,
            passenger: MobileStepbarStepContent.passenger,
            baggage: MobileStepbarStepContent.baggage
        },
        MobileStepbarStyles: {
            stepIndex_label: MobileStepbar.stepIndex__label,
            stepIndex: MobileStepbarStep.stepIndex,
            header: MobileStepbar.header,
            stepbar: MobileStepbar.stepbar
        }
    },
    MobileSegmentInfo: {
        MobileSegmentFlat: {
            title: MobileSegmentFlat.title,
            aircraft__name: MobileFlight.aircraft__name
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            wrapper: MobileToolbar.wrapper,
            button: MobileToolbar.button,
            button_back: MobileToolbar.button_back,
            button_continue: MobileToolbar.button_continue
        }
    },
    Insurance: {
        MobileScreenStyles: {
            header: cn(MobileScreenStyles.header, MobileScreen.header),
            close: MobileScreen.close
        },
        InsuranceCardStyles: {
            insurance: InsuranceCard.insurance,
            selected: InsuranceCard.selected,
            insurance__header: InsuranceCard.insurance__header,
            insurance__money: InsuranceCard.insurance__money,
            option: InsuranceCard.option,
            insurance__button: InsuranceCard.insurance__button,
            insurance__button_selected: InsuranceCard.insurance__button_selected
        },
        InsuranceStyles: {
            insurances: MobileScreenStyles.insurances,
            dialog__button_done: Insurance.dialog__button_done,
            dialog__button_clear: Insurance.dialog__button_clear
        }
    },
    InsurancePopup: {
        InsurancePopup: {
            header: Insurance.insurancePopup__header
        }
    },
    VipServiceSelect: {
        VipServiceSelect: {
            cancel: cn(VipServiceSelect.cancel, BaggageDelivery.vipServiceSelect__cancel),
            confirm: cn(VipServiceSelect.confirm, BaggageDelivery.vipServiceSelect__confirm),
            header: cn(BaggageDelivery.vipServiceSelect__header),
            direction: cn(BaggageDelivery.vipServiceSelect__direction)
        }
    },
    PaymentResult: {
        PaymentResult: {
            wrapper: PaymentResult.wrapper,
            header: PaymentResult.header,
            dividerIcon: PaymentResult.dividerIcon,
            dividerIcon_failed: PaymentResult.dividerIcon_failed,
            dividerIcon_success: PaymentResult.dividerIcon_success,
            redirectButton: PaymentResult.redirectButton,
            button_failed: PaymentResult.button_failed
        }
    },
    Aeroexpress: {
        Aeroexpress: {
            modal: AeroexpressModalContent.modal,
            modal__footerBtn: AeroexpressModalContent.modal__footerBtn,
            aeroexpress__scrollBody: AeroexpressModalContent.aeroexpress__scrollBody,
            aeroexpress_paper: AeroexpressModalContent.aeroexpress_paper
        },
        ModalContent: {
            modal__title: AeroexpressModalContent.modal__title,
            button_remove: AeroexpressModalContent.button_remove
        }
    },
    PromoLoader: {
        PromoLoader: {
            promo__content: PromoLoader.promo__content,
            promo__image: PromoLoader.promo__image
        }
    },
    StatusMessage: {
        StatusMessage: {
            statusMessage_success: StatusMessage.statusMessage_success
        }
    },
    PaymentTimeLimit: {
        PaymentTimeLimitPage: {
            wrapper: PaymentTimeLimitPage.wrapper,
            button: PaymentTimeLimitPage.button,
            timeImage: PaymentTimeLimitPage.timeImage,
            text: PaymentTimeLimitPage.text
        }
    },
    WarningModal: {
        WarningModal: {
            buttons: FlightNotAvailable.buttons
        }
    },
    ScheduleForm: {
        ScheduleForm: {
            title: ScheduleForm.title,
            button: ScheduleForm.button,
            location: ScheduleForm.location,
            from: ScheduleForm.from,
            to: ScheduleForm.to,
            input: ScheduleForm.input
        }
    },
    CoreSchedule: {
        ScheduleFlight: {
            flight: ScheduleFlight.flight,
            routeArrow__route: ScheduleFlight.routeArrow__route,
            routeArrow__circle: ScheduleFlight.routeArrow__circle,
            routeArrow__timeInRoute: ScheduleFlight.routeArrow__timeInRoute
        },
        Location: {
            container: ScheduleLocation.container
        },
        WeekDays: {
            day: ScheduleWeekDays.day
        },
        DateSwitcher: {
            selector: ScheduleDateSwitcher.selector
        },
        Weekpicker: {
            months__wrapper: ScheduleWeekpicker.months__wrapper,
            months__prev: ScheduleWeekpicker.months__prev,
            months__next: ScheduleWeekpicker.months__next,
            day__today: ScheduleWeekpicker.day__today,
            day__selected: ScheduleWeekpicker.day__selected,
            day__start: ScheduleWeekpicker.day__start,
            day__end: ScheduleWeekpicker.day__end
        }
    },
    LoyaltyInfo: {
        Card: {
            authorized: LoyaltyInfo.authorized,
            nickel: LoyaltyInfo.nickel,
            gold: LoyaltyInfo.gold
        }
    },
    TicketExample: {
        TicketExample: {
            ticketExample: TicketExample.ticketExample
        }
    },
    Money: {
        Money: {
            money: Money.money
        }
    },
    MobileAuthForm: {
        MobileAuthForm: {
            wrapper: Checkout.mobileAuthForm__wrapper
        }
    }
};
export default theme;
