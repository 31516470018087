import { useUpgradeFlightClass as useStore } from '../store';
import { useSelector } from 'react-redux';
import { getOrder } from '../../Checkout/store/order/selectors';
import { useCallback, useMemo } from 'react';
import { useRefundSummary } from '../../RefundSummary';
import { useSelectors } from '../store/selectors';
export var useUpgradeFlightClass = function (_a) {
    var onBackClick = _a.onBackClick, loading = _a.loading, onSaveServices = _a.onSaveServices;
    var order = useSelector(getOrder);
    var _b = useStore(order), state = _b.state, dispatch = _b.dispatch;
    var selectors = useSelectors(state, order);
    var _c = useRefundSummary(order.id, order.travellers, state.service.allowedPassengers.map(function (group) { return group[0]; }), state.selectedSegments, order.flight.segments.length === state.selectedSegments.length), gdsServicesRefund = _c.gdsServicesRefund, refundServicesLoading = _c.loading;
    var handleToggleSegment = function (segmentId) { return dispatch({ type: 'toggleSegment', payload: segmentId }); };
    var handleSetStep = function (step) { return dispatch({ type: 'setStep', payload: step }); };
    var handleGoToReview = useCallback(function () {
        handleSetStep('review');
    }, [state.selectedSegments]);
    var handleConfirm = useCallback(function () {
        onSaveServices(selectors.saveOrderServicesVariables);
    }, [selectors.saveOrderServicesVariables]);
    var toolbarCallbacks = useMemo(function () {
        if (state.step === 'select') {
            return {
                next: handleGoToReview,
                back: onBackClick
            };
        }
        else {
            return {
                next: handleConfirm,
                back: function () { return handleSetStep('select'); }
            };
        }
    }, [state.step, handleGoToReview, handleConfirm]);
    return {
        order: order,
        step: state.step,
        transferDurationBySegments: selectors.transferDurationBySegments,
        refundServicesLoading: refundServicesLoading,
        gdsServicesRefund: gdsServicesRefund,
        toggleSegment: handleToggleSegment,
        service: state.service,
        flights: state.flights,
        selectedSegments: state.selectedSegments,
        isServicesSaving: loading,
        toolbarCallbacks: toolbarCallbacks,
        totalPrice: state.totalPrice
    };
};
