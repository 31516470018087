import * as React from 'react';
import { CartService } from '../../Cart/types';
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { getActualProduct } from '../utils';
import { useTranslation } from 'react-i18next';
import CommonSummary from './CommonSummary';
var UpgradeFlightClassSummary = function (props) {
    var t = useTranslation('Cart').t;
    var onDeleteHandler = function (travellerId, serviceId, segmentId, allSegments) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Extra, {
            type: OrderAdditionalServiceGdsServiceServiceType.Vip,
            passengerId: travellerId,
            segmentId: segmentId,
            serviceId: serviceId,
            allSegments: allSegments
        });
    };
    return (React.createElement(CommonSummary, { flight: props.flight, services: props.services, travellersById: props.travellersById, travellers: props.travellers, groupType: OrderAdditionalServiceGdsServiceServiceType.Vip, serviceFilterPredicate: function (service) { return service.subType === GdsServiceVipSubType.Upgrade; }, serviceOptionItemFactory: function (_a) {
            var selectedService = _a.selectedService, key = _a.key, travellerId = _a.travellerId;
            var segment = props.flight.segments.find(function (segment) { return segment.segment.id === key; }), direction = key === 'allSegments'
                ? t('All flights')
                : "".concat(segment.segment.departure.airport.iata, " - ").concat(segment.segment.arrival.airport.iata);
            var actualProduct = getActualProduct(selectedService.products);
            return {
                name: "".concat(direction),
                id: selectedService.service.id,
                included: selectedService.count === selectedService.confirmedCount,
                price: selectedService.service.price,
                status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                onDelete: function () { return onDeleteHandler(travellerId, selectedService.serviceId, key, key === 'allSegments'); }
            };
        }, serviceOptionGroupFactory: function (_a) {
            var items = _a.items, travellerId = _a.travellerId;
            var newItems = [];
            if (!!items.length) {
                newItems.push({
                    traveller: props.travellersById.get(travellerId),
                    headerType: 'travellerName',
                    items: items,
                    headerIcon: null
                });
            }
            return newItems;
        } }, function (children) { return props.children(children); }));
};
export default UpgradeFlightClassSummary;
