// @ts-ignore
import buildFormatLongFn from 'date-fns/locale/_lib/buildFormatLongFn';
var dateFormats = {
    full: "EEEE, do MMMM y 'с.'",
    long: "do MMMM y 'с.'",
    medium: "d MMM y 'с.'",
    short: 'dd.MM.y'
};
var timeFormats = {
    full: 'H:mm:ss zzzz',
    long: 'H:mm:ss z',
    medium: 'H:mm:ss',
    short: 'H:mm'
};
var dateTimeFormats = {
    full: "{{date}} 'соати' {{time}}",
    long: "{{date}} 'соати' {{time}}",
    medium: '{{date}}, {{time}}',
    short: '{{date}}, {{time}}'
};
var formatLong = {
    date: buildFormatLongFn({
        formats: dateFormats,
        defaultWidth: 'full'
    }),
    time: buildFormatLongFn({
        formats: timeFormats,
        defaultWidth: 'full'
    }),
    dateTime: buildFormatLongFn({
        formats: dateTimeFormats,
        defaultWidth: 'full'
    })
};
export default formatLong;
