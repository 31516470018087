var _a;
import { getLocale } from '@websky/core/src/utils';
import i18next from 'i18next';
import { CardType } from '@websky/core/src/PromoLoader/types';
import { OauthServiceType } from '@websky/graphql';
var currentLocale = getLocale();
var isProd = process.env.NODE_ENV === 'production';
var localeLangMap = new Map([['kk', 'kz']]);
currentLocale = localeLangMap.has(currentLocale) ? localeLangMap.get(currentLocale) : currentLocale;
var currentLocaleNordStar = currentLocale === 'ru' ? '' : "".concat(currentLocale, "/");
export var promoLoaders = [
    {
        type: CardType.City,
        city: 'Istanbul',
        title: 'Discover new horizons',
        text: 'International flights from Kazakhstan',
        image: 'https://nordstar.ru/local/templates/nordstar2020/img/Loader/loader.gif'
    }
];
export var nordstarPassengersConfig = [
    {
        code: 'ADT',
        isAdult: true,
        defaultCount: 1
    },
    {
        code: 'SCF',
        parentCode: 'ADT',
        isAdult: true,
        noAvailableWith: ['ADT', 'INF']
    },
    {
        code: 'SCM',
        parentCode: 'ADT',
        isAdult: true,
        noAvailableWith: ['ADT', 'INF']
    },
    {
        code: 'МЛА',
        parentCode: 'ADT',
        isAdult: true,
        noAvailableWith: ['ADT']
    },
    {
        code: 'CLD',
        isAdult: false,
        maxCountWithoutAdult: 1
    },
    {
        code: 'INF',
        isAdult: false
    },
    {
        code: 'РВТ',
        parentCode: 'INS',
        isAdult: false
    }
];
export var companyInfo = {
    baggageAnimalsURL: 'https://www.nordstar.ru/services/animals/',
    facebook: '',
    instagram: '',
    vk: 'https://vk.com/nordstar_airlines',
    baggage: 'https://www.nordstar.ru/services/check-in/baggage.php',
    meal: 'https://www.nordstar.ru/services/food/',
    seat: 'https://www.nordstar.ru/services/seat-selection/',
    privacyPolicy: 'https://www.nordstar.ru/about/privacy-policy/',
    exarePrivacyPolicyURL: 'https://www.nordstar.ru/about/privacy-policy/',
    exareRulesURL: 'https://www.nordstar.ru/e-ticket/return/',
    purchaseTermsUrl: 'https://www.nordstar.ru/e-ticket/purchase-terms/',
    insurance: 'https://www.nordstar.ru/services/insurance/',
    subsidyFaresConditionURL: '',
    loyaltyProgramRegistrationUrl: 'https://www.nordstar.ru/bonus-programs/letat-legko/about/',
    loyaltyProgramName: 'Летать легко!',
    iataCode: 'Y7',
    loyaltyProgramAccountUrl: 'https://www.nordstar.ru/account/#/account/loyalty',
    loyalty: {
        links: [
            {
                title: 'Status',
                url: 'https://www.nordstar.ru/bonus-programs/status/'
            },
            {
                title: 'Earn miles',
                url: 'https://www.nordstar.ru/bonus-programs/collect/'
            },
            {
                title: 'Use miles',
                url: "https://www.nordstar.ru/".concat(currentLocaleNordStar, "bonus-programs/spend/")
            },
            {
                title: 'FAQ',
                url: "https://www.nordstar.ru/".concat(currentLocaleNordStar, "bonus-programs/rules/")
            }
        ]
    },
    multiInsurance: true,
    specialDocumentForInsurance: false,
    farelockURL: currentLocale === 'ru'
        ? 'https://www.nordstar.ru/services/extended-payment-deadline/'
        : 'https://www.nordstar.ru/en/services/extended-payment-deadline/'
};
export var i18n = {
    ru: {
        LoyaltyBonus: {
            'LightJet Club': 'Летать легко!'
        },
        Datepicker: {
            week_short_0: 'Пн.',
            week_short_1: 'Вт.',
            week_short_2: 'Ср.',
            week_short_3: 'Чт.',
            week_short_4: 'Пт.',
            week_short_5: 'Сб.',
            week_short_6: 'Вс.'
        },
        Title: {
            companyName: 'NordStar'
        },
        Consents: {
            agreement_rules: 'Я подтверждаю, что ознакомлен(а) и соглашаюсь {{links}}',
            agreement_interpolation: '{{generalRules}}{{insurancesRules}}',
            agreement_generalRules: ' <span>с <a target="_blank" href="https://www.nordstar.ru/e-ticket/purchase-terms/">условиями электронной покупки</a> и <a target="_blank" href="https://www.nordstar.ru/passengers/regulations/">правилами перевозок</a>, а так же условиями и ограничениями для каждой выбранной услуги</span>',
            agreement_insurancesRules: ' <span>с <a target="_blank" href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf">правилами страхования</a> и <span>{{insurancesProductsLinks}}</span>, оплатой выбранных дополнительных услуг</span>',
            agreement_additionalInsuranceRules: 'условиями публичной оферты {{link}}'
        },
        SubsidySuggestPopup: {
            'Subsidy fares': 'Внимание'
        },
        LoginPage: {
            'Please enter a valid Email / Phone / Card number': 'Пожалуйста, введите Эл. почту / Номер карты',
            'Email / Phone / Card number': 'Электронная почта'
        },
        Refund: {
            'Request for refund': 'Запрос на возврат',
            'The request was deleted. Want to create a new request?': 'Отменить предварительный расчет суммы к возврату'
        },
        Aircraft: {
            'The Embraer E-Jet E2 family are medium-range jet airliners developed by Embraer, succeeding the original E-Jet. The program was launched at the Paris Air Show in 2013. The first variant, the E190-E2, took its first flight on 23 May 2016 and was certified on February 28, 2018 before entering service with Widerøe on 24 April. The three twinjet variants share the same four-abreast narrow-body fuselage with different lengths and three different new wings, Pratt & Whitney PW1000G turbofans in two sizes, fly-by-wire controls with new avionics, and an updated cabin.': 'Семейство Embraer E-Jet E2 - реактивные авиалайнеры средней дальности, разработанные Embraer, сменившие оригинальный E-Jet. Программа была запущена на авиасалоне в Париже в 2013 году. Первый вариант, E190-E2, совершил свой первый полет 23 мая 2016 года и был сертифицирован 28 февраля 2018 года, после чего 24 апреля вступил в строй с Widerøe. Три варианта Twinjet имеют один и тот же четырехконтурный узкий кузов с разной длиной и тремя новыми крыльями, турбореактивные вентиляторы Pratt & Whitney PW1000G двух размеров, элементы управления на лету с новой авионикой и обновленную кабину.',
            'Range (full pax)': 'Дальность полета'
        },
        Account: {
            'Resend it': 'Отправить еще раз'
        },
        Checkin: {
            'Inflight insurance or baggage insurance is your travel confidence.': 'Страховка на время перелета или страхование багажа - ваша уверенность в путешествии.',
            'Anything else for your trip?': 'Что-нибудь еще?',
            'Online check-in': 'Онлайн-регистрация на рейс',
            'An unexpected error has occurred during the start of the check-in process. Please try again later or contact a customer support service.': 'Повторите попытку регистрации на рейс еще раз, либо обратитесь в контактный центр авиакомпании.'
        },
        Checkout: {
            pet_popup_rules: [
                'Мы принимаем к перевозке в салоне воздушного судна: собак, кошек, а также птиц',
                'Ваш питомец должен весить не более 10 кг (вместе с контейнером или клеткой)',
                'Высота контейнера (клетки) не должна превышать 23 см',
                'Рассадка пассажиров, перевозящих животных/птиц в пассажирском салоне, запрещена на рядах 1,3 и местах 2DF',
                'На 1 взрослого пассажира разрешается только 1 животное'
            ],
            'Take your pet in a trip': 'Не расставайтесь с самым верным другом даже в пути.',
            Animal: 'Перевозка животных',
            'Do not break up with the most faithful friend even during the journey.': 'Не расставайтесь с самым верным другом даже в пути.',
            'Please, choose another flight.': 'Пожалуйста, выберите другой перелет.',
            'Choose another flight': 'Выбрать другой перелет',
            Flight: 'Перелет',
            'Selected options': 'Выбранные перелеты',
            'Inflight insurance or baggage insurance is your travel confidence.': 'Страховка на время перелета или страхование багажа - ваша уверенность в путешествии.',
            'Now you will be redirected to the previous version of the site. There you can choose ancillary services.': 'Сейчас мы направим вас на предыдущую версию сайта. Временно, именно там, вы сможете оформить нужные вам дополнительные услуги и сделать ваш полет незабываемым.',
            'Improve your flight right now by adding the pleasant and necessary comfort with special food, extra baggage and the best places with amazing views.': 'Сделайте ваш полет особенным. Добавьте в ваш заказ изысканное питание, дополнительный багаж и ручную кладь или насладитесь впечатляющим видом из иллюминатора.',
            'Feel the taste in flight!': 'Почувствуйте вкус в полете!',
            'terms of tariff': 'условиями применения тарифа',
            'If you will not select a seat now, we will automatically select it for you upon check-in.': 'Забронируйте Ваше любимое кресло прямо сейчас, и вы гарантированно будете зарегистрированы именно на него.',
            'Please, read the rules of service and accept it': 'Для продолжения, пожалуйста, подтвердите согласие со всеми услугами или откажитесь от дополнительных услуг/измените их параметры, нажав «Изменить»'
        },
        PassengersToolbar: {
            'Please, read the rules of service and accept it': 'Для продолжения, пожалуйста, подтвердите согласие с условиями обработки персональных данных и условиями применения тарифа'
        },
        Exchange: {
            'terms of tariff': 'условиями применения тарифа',
            'After submitting the request, the seats you have selected will be canceled.': 'После отправки запроса будут отменены все выбранные места по первоначальному авиабилету.'
        },
        CheckoutOrderSummary: {
            'View details': 'Детали перелета'
        },
        FlightInfo: {
            Details: 'Детали перелета'
        },
        Loyalty: {
            'Miles recovery': 'Восстановить полеты',
            'Log in to LightJet Club': 'Вход в личный кабинет Летать легко!',
            'Earn miles': 'Накопление миль',
            Status: 'Статусы и привилегии'
        },
        MealPage: {
            'Feel the taste in flight': 'Закажите любимые блюда\n\n\n'
        },
        MilesRecovery: {
            'Your can get miles for flights your travelled before you signed in loyalty program': 'Вы можете запросить мили за неучтенные полеты:\n\n\n• Только по уже совершённым полётам.\n• За полёты, совершённые не более 6 месяцев назад.',
            'PNR or ticket number': 'Номер билета',
            'Order not found': 'Заказ не найден, просим обратиться через <a href="https://www.nordstar.ru/about/feedback/" target="_blank">форму обратной связи</a>'
        },
        MobileStepbar: {
            Flights: 'Перелет'
        },
        Passengers: {
            'Young adult': 'Молодежь',
            '12+ years': '23+ лет',
            '12-23 years': '12-22 лет',
            'Adults over twelve years old': 'Взрослые старше двадцати трех лет',
            'Young peoples 12-23 years old': 'Молодежь от двенадцати до двадцати двух лет'
        },
        PriceLock: {
            'I want to lock the price for this flight': 'Хочу заморозить цену на этот перелет'
        },
        QuickSearchForm: {
            МЛА_0: 'Пассажир (молодежь)',
            МЛА_1: 'Пассажира (молодежь)',
            МЛА_2: 'Пассажиров (молодежь)'
        },
        Results: {
            'Best flight': 'Лучший перелет',
            'Selected flights': 'Выбранные перелеты',
            'If you still have questions, please contact the Contact Center.': 'Если у вас остались вопросы, свяжитесь с Контактным центром по телефону 8-800-700-8-007 (бесплатно по России).'
        },
        SeatMap: {
            'Next Flight': 'След. перелет',
            'If you don\'t select a seat, we\'ll automatically allocate your seats when you check-in.': 'Забронируйте Ваше любимое кресло прямо сейчас, и вы гарантированно будете зарегистрированы именно на него.',
            '- Placement in the front row of the cabin, if one was originally selected.': '— Размещение на местах повышенной комфортности в начале салона экономического класса или на местах возле аварийных выходов, если таковое было выбрано изначально.'
        },
        ServiceRecommendation: {
            'on all flight directions': 'на всех направлениях перелета'
        },
        Stepbar: {
            Flights: 'Перелеты'
        },
        Timer: {
            'Resend it': 'Отправить еще раз'
        },
        Toolbar: {
            'miles will be awared': 'миль за этот полет'
        },
        AdditionalBaggage: {
            'More bags?': 'Дополнительный багаж',
            'Buy now and save 20-30%': 'Сэкономьте сейчас до 30%',
            'Check-in baggage': 'Дополнительный багаж'
        },
        IncludedBaggage: {
            'Need to bring sports, music or baby equipments?': 'Вам нужно взять с собой спортивное оборудование?'
        },
        Passenger: {
            email: 'Электронная почта'
        },
        PassengerTypes: {
            YTH: 'Молодежь'
        },
        Contacts: {
            Email: 'Электронная почта'
        },
        RetrieveBooking: {
            'Security code': 'Фамилия пассажира'
        },
        OrderSummary: {
            'Currently there are no active bookings made through your private profile.': 'В настоящий момент нет бронирований, сделанных через ваш личный профиль.'
        },
        Traveller: {
            Documents: 'Документы',
            Contacts: 'Контакты'
        },
        PromoCode: {
            'Promo code': 'Промокод'
        },
        AdditionalServiceCard: {
            Clear: 'Отказаться'
        },
        PaymentTimeLimit: {
            'Sorry,': 'Время на оформление заказа истекло.',
            'time to complete booking has expired': 'Пожалуйста, начните новый поиск.'
        },
        FareLock: {
            'Fixation the cost of the air ticket': 'Увеличенный срок для оплаты билета',
            'No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}': 'Если планы по перелёту еще могут измениться, будьте уверены, что ваш билет дождется именно вас и именно по такой стоимости. Увеличьте срок для планирования перелёта и оформления билета на {{hours}} {{plural}}',
            'Lock fare': 'Добавить',
            'Confirmed fare lock': 'Увеличенный срок для оплаты билета',
            'If you make the decision to fly on the selected date within the next {{hours}} {{plural}}, you will be able to pay the full amount of the booking at the fare you locked in. Please, be advised that Fare lock fee is non-refundable.': 'Вы можете гарантированно оформить билет по выбранной стоимости и произвести оплату в течение {{hours}} {{plural}}. По истечению данного времени для оформления билета потребуется создать новое бронирование. Создание нового бронирования будет возможно только в случае наличия билетов на данном рейсе, а так же по действующей на момент создания бронирования стоимости. Сбор за услугу не возвращается.'
        },
        ScheduleForm: {
            Schedule: 'Расписание рейсов'
        }
    },
    en: {
        LoyaltyBonus: {
            'LightJet Club': 'Easy to Fly!'
        },
        LoginPage: {
            'Please enter a valid Email / Phone / Card number': 'Please enter a valid Email / Card number',
            'Email / Phone / Card number': 'Email'
        },
        MilesRecovery: {
            'Your can get miles for flights your travelled before you signed in loyalty program': 'You can request miles for unrecorded flights:\n\n\n• For completed flights only.\n• For flights flown no more than six months ago.',
            'PNR or ticket number': 'Ticket number',
            'Order not found': 'Order not found, please reach out to us via <a href="https://www.nordstar.ru/en/about/feedback/" target="_blank">feedback form</a>'
        },
        SeatMap: {
            'The airline reserves the right to replace the aircraft.': 'The airline reserves the right to replace the aircraft type.',
            'Pay attention!': 'Please pay your attention!',
            '- Placement in the front row of the cabin, if one was originally selected.': '- Placement in the front row of the cabin, if one was originally selected.\n- Extra comfort seats in the front row of the cabin or near emergency exits, if one was originally selected',
            'In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters': 'In the event of an aircraft type replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters'
        },
        Loyalty: {
            'Log in to LightJet Club': 'Log in to NordStar  Club',
            Status: 'Statuses and privileges',
            'Earn miles': 'Earn miles',
            FAQ: 'Program rules'
        },
        Exchange: {
            'After submitting the request, the seats you have selected will be canceled.': 'After sending the request, all selected seats on the original ticket will be cancelled.',
            'terms of tariff': 'Terms of tariff'
        },
        AdditionalBaggage: {
            'Buy now and save 20-30%': 'Buy now and save up to 30%'
        },
        SubsidySuggestPopup: {
            'Subsidy fares': 'Attention',
            'For special categories of passengers on the flight, you can choose special subsidy fares': 'A subsidized fares are available on this flight. Please select your category to apply.'
        },
        Account: (_a = {},
            _a[companyInfo.loyaltyProgramName] = 'Easy to Fly!',
            _a),
        Checkout: {
            'Take your pet in a trip': 'Do not break up with the most faithful friend even during the journey.',
            pet_popup_rules: [
                'We accept for transportation in the cabin of the aircraft: dogs, cats, as well as birds',
                'Your pet must weigh no more than 10 kg (including container or cage)',
                'The height of the container (cage) should not exceed 23 cm',
                'Only 1 animal is allowed per adult passenger',
                'Seats in row 1, 3 and seats 2DF are not available for passengers carrying animals in cabin'
            ],
            Animal: 'Transportation of animals',
            'Do not break up with the most faithful friend even during the journey.': 'Do not break up with the most faithful friend even during the journey.',
            'Please, read the rules of service and accept it': 'Please, confirm your agreement with all services or refuse additional services/change their parameters by clicking "Edit"'
        },
        PassengersToolbar: {
            'Please, read the rules of service and accept it': 'Please, confirm your agreement with the terms of personal data processing and the terms of application of the tariff'
        },
        Traveller: {
            Documents: 'Documents',
            Contacts: 'Contacts',
            'With the help of this data, we will be able to inform the fellow traveler about changes in booking, flight status, check-in time, etc. Optional data.': 'With the help of this data, we will be able to inform the fellow traveler about changes in booking, flight status, check-in time, etc. Optional data.',
            'Save the details of your internal or foreign passport, this will speed up the process of purchasing tickets.': 'Save the details of your internal or foreign passport, this will speed up the process of purchasing tickets.'
        },
        Consents: {
            agreement_rules: 'I acknowledge that I have read and agree {{links}}',
            agreement_interpolation: '{{generalRules}}{{insurancesRules}}',
            agreement_generalRules: ' <span>с <a target="_blank" href="https://www.nordstar.ru/e-ticket/purchase-terms/">e-purchase terms</a> and <a target="_blank" href="https://www.nordstar.ru/passengers/regulations/">transport regulations</a>, as well as the conditions and restrictions for each selected service</span>',
            agreement_insurancesRules: ' <span>с <a target="_blank" href="https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf">insurance rules</a> и <span>{{insurancesProductsLinks}}</span>, by paying for selected additional services</span>',
            agreement_additionalInsuranceRules: 'the terms of the public offer {{link}}'
        },
        AdditionalServiceCard: {
            Clear: 'Cancel'
        },
        Passengers: {
            '12+ years': '23+ years',
            '12-23 years': '12-22 years',
            'Adults over twelve years old': 'Adults over twenty three years old',
            'Young peoples 12-23 years old': 'Young people from 12 to 22 years old'
        },
        PassengerTypes: {
            YTH: 'Passenger (youth)'
        },
        FareLock: {
            'Fixation the cost of the air ticket': 'Extended term for ticket payment',
            'No need to worry about the price increase or about the fact that all tickets will be sold out, now you can fix the ticket price for {{hours}} {{plural}}': 'If your flight plans can still change, be sure that your ticket will wait for you and at this price. Extend the period for planning a flight and issuing a ticket by {{hours}} {{plural}}',
            'Lock fare': 'Add',
            'Confirmed fare lock': 'Extended term for ticket payment',
            'If you make the decision to fly on the selected date within the next {{hours}} {{plural}}, you will be able to pay the full amount of the booking at the fare you locked in. Please, be advised that Fare lock fee is non-refundable.': 'You can securely issue a ticket at the selected cost and make payment within {{hours}} {{plural}}. After this time has elapsed, you will need to create a new booking to issue a ticket. Creating a new booking will be possible only if there are tickets for this flight, as well as at the cost valid at the time the booking was created. The service fee is non-refundable.'
        },
        Results: {
            'If you still have questions, please contact the Contact Center.': 'If you still have questions, please contact the Contact Center by phone 8-800-700-8-007 (free within Russia).'
        },
        Refund: {
            'The request was deleted. Want to create a new request?': 'Cancel the preliminary calculation of the refund amount'
        }
    }
};
export var defaultSteps = {
    flights: {
        label: i18next.t('Stepbar:Flights')
    },
    seats: {
        label: i18next.t('Stepbar:Seats')
    },
    baggage: {
        label: i18next.t('Stepbar:Baggage')
    },
    meal: {
        label: i18next.t('Stepbar:Meal')
    },
    passengers: {
        label: i18next.t('Stepbar:Passengers')
    },
    order: {
        label: i18next.t('Stepbar:Order')
    },
    payment: {
        label: i18next.t('Stepbar:Payment')
    }
};
export var config = {
    global: {
        servicesAvailable: {
            animal: true
        },
        showIsActualizationResultOk: false,
        companyInfo: companyInfo,
        maxBaggagePerPassenger: 4,
        i18n: i18n,
        findOrderURL: "https://www.nordstar.ru/".concat(currentLocaleNordStar, "booking/#/find"),
        reserveEngines: {
            siteUrl: "https://www.nordstar.ru/".concat(currentLocaleNordStar),
            redirectToWebsky1: true
        },
        socialAuthMethods: [
            {
                service: OauthServiceType.Vkontakte,
                isAvailable: true
            },
            {
                service: OauthServiceType.TinkoffID,
                isAvailable: true
            }
        ],
        authMethods: {
            phone: false
        },
        allowPromoCodes: true,
        currentUserInGlobalScope: true,
        seatMap: {
            showRecommendedSeats: true
        }
    },
    Engine: {
        searchFormURL: isProd ? './' : null,
        calendarDaysCount: 7,
        promoLoaders: promoLoaders,
        showAvailableFaresOnly: false,
        resultsFareSelectorInModal: false,
        defaultSteps: defaultSteps
    },
    SearchForm: {
        payByMiles: true,
        showPriceGraph: false,
        enableComplexRoute: false,
        showPricesInDatepicker: true,
        passengersConfig: nordstarPassengersConfig
    },
    Account: {
        showSubscribeCheckbox: true,
        engineURL: "https://www.nordstar.ru/".concat(currentLocaleNordStar, "booking")
    }
};
