import { createTheme } from '../utils';
import * as MobileSegmentInfo from './components/MobileSegmentInfo.css';
import * as MobileSegmentFlat from './components/MobileSegmentFlat/MobileSegmentFlat.css';
import * as MobileSegmentUpgrade from './components/MobileSegmentUpgrade/MobileSegmentUpgrade.css';
export var defaultTheme = {
    MobileSegmentInfo: MobileSegmentInfo,
    MobileSegmentFlat: MobileSegmentFlat,
    MobileSegmentUpgrade: MobileSegmentUpgrade
};
export default defaultTheme;
export var useTheme = createTheme(defaultTheme).useTheme;
