import { __assign } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { format, getUserValue, MOBILE_MIN_WIDTH } from '../../../../../utils';
import Chip from '../Inner/Selections/Chip/Chip';
import { useTheme } from '../../../../../theme';
import RequestControls from './RequestControls/RequestControls';
import Notice from '../../../../../RefundSummary/components/Notice/Notice';
import SelectedFlights from '../Request/SelectedFlights/SelectedFlights';
import { ExareStatus, TravellerFieldEnum } from '@websky/graphql';
import { Reasons } from '../../../store/types';
import { useHtmlDescriptionOfError } from '../hooks';
var RequestForm = function (_a) {
    var _b, _c;
    var order = _a.order, deleteRequest = _a.deleteRequest, goToNewExchange = _a.goToNewExchange, goToPayment = _a.goToPayment;
    var theme = useTheme('Exchange').RequestForm;
    var t = useTranslation('Exchange').t;
    var passengers = React.useMemo(function () {
        return order.travellers.map(function (traveller) {
            var firstName = getUserValue(traveller, TravellerFieldEnum.FirstName), lastName = getUserValue(traveller, TravellerFieldEnum.LastName);
            return "".concat(firstName, " ").concat(lastName);
        });
    }, [order]);
    var segments = React.useMemo(function () {
        if (!order.flight) {
            return [];
        }
        return order.flight.segments.map(function (segment) {
            return "".concat(segment.segment.departure.airport.city.name, " - ").concat(segment.segment.arrival.airport.city.name);
        });
    }, [order]);
    var prices = React.useMemo(function () {
        var _a, _b;
        var charges = null;
        if (order.prices.agencyChargesForExare) {
            charges = __assign(__assign({}, order.prices.agencyChargesForExare), { amount: order.prices.agencyChargesForExare.amount + ((_b = (_a = order.prices.exchangeAirlinePenalty) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0) });
        }
        if (order.status === ExareStatus.InProcess && !order.automaticMode) {
            return null;
        }
        return {
            priceToPay: order.prices.priceToPay,
            charges: charges
        };
    }, [order.prices, order.status, order.automaticMode]);
    var htmlDescriptionOfWarning = useHtmlDescriptionOfError(order.warnings, (_b = {},
        _b['Exchange operation allowed only in manual mode'] = {
            header: t('Part of the route was flown, part of the ticket is used. The application for exchange will be considered manually by the airline')
        },
        _b));
    return (React.createElement("div", null,
        React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.request__main },
                React.createElement("div", { className: theme.header__wrapper },
                    React.createElement("div", { className: theme.header },
                        t('Request'),
                        " ",
                        React.createElement("span", null,
                            " \u2116",
                            order.id)),
                    React.createElement("div", { className: theme.created },
                        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                            t('Create'),
                            " ",
                            format(order.createDate, 'dd MMMM, yyyy')),
                        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                            format(order.createDate, 'dd MMMM, yyyy'),
                            React.createElement("span", { className: theme.createdTime }, format(order.createDate, 'HH:mm'))))),
                order.status !== ExareStatus.Finished && (React.createElement("div", { className: cn(theme.status, (_c = {},
                        _c[theme.status_error] = order.status === ExareStatus.TimelimitExpired,
                        _c[theme.status_reject] = order.status === ExareStatus.Rejected || order.status === ExareStatus.Refused,
                        _c[theme.status_awaitingPayment] = order.status === ExareStatus.AwaitingPayment,
                        _c)) }, t(order.status)))),
            React.createElement("div", { className: theme.order },
                passengers.map(function (traveller, key) {
                    return (React.createElement(Chip, { label: traveller, type: "passenger", onClick: function () { }, key: "passenger_".concat(key), className: theme.chip, readonly: true }));
                }),
                React.createElement(Chip, { label: order.isCompelled ? t(Reasons.Unvoluntary) : t(Reasons.Voluntary), type: "passenger", onClick: function () { }, className: cn(theme.chip), readonly: true }),
                segments.map(function (segment, id) {
                    return (React.createElement(Chip, { label: segment, type: "flight", onClick: function () { }, key: "segment_".concat(id), className: theme.chip, readonly: true }));
                })),
            order.userComment && (React.createElement("div", { className: theme.comment },
                React.createElement("div", { className: theme.comment__header }, t('Your comments')),
                React.createElement("div", { className: theme.comment__text }, order.userComment))),
            order.flight && (React.createElement(SelectedFlights, { selectedFlights: [order.flight], prices: prices, hideHeader: true, type: "flat" }))),
        (order.status === ExareStatus.AwaitingPayment || order.status === ExareStatus.TimelimitExpired) && (React.createElement(Notice, { type: "time", header: t('Limited exchange time'), text: t('Offer valid until {{date}}', { date: format(order.timelimit, 'dd MMMM yyyy, HH:mm') }) })),
        order.status === ExareStatus.Rejected && order.managerRejectComment && (React.createElement(Notice, { type: "reject", header: t('Rejection reason'), text: order.managerRejectComment })),
        htmlDescriptionOfWarning && (React.createElement(Notice, { type: "error", header: htmlDescriptionOfWarning === null || htmlDescriptionOfWarning === void 0 ? void 0 : htmlDescriptionOfWarning.header, html: htmlDescriptionOfWarning === null || htmlDescriptionOfWarning === void 0 ? void 0 : htmlDescriptionOfWarning.body })),
        React.createElement(RequestControls, { order: order, deleteRequest: deleteRequest, goToNewExchange: goToNewExchange, goToPayment: goToPayment })));
};
export default RequestForm;
