import { __assign } from "tslib";
import { GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import flatten from 'lodash/flatten';
import { isCheckoutOrderGuard } from '../../utils';
export var upgradeClassStateFactory = function (order) {
    var _a, _b, _c;
    var state = {
        orderId: order.id,
        service: null,
        selectedSegments: [],
        step: 'select',
        travellersIds: [],
        flights: []
    };
    var orderCurrency = order.price.total.currency;
    state.service = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(function (service) {
        return service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
            service.subType === GdsServiceVipSubType.Upgrade;
    });
    if (isCheckoutOrderGuard(order)) {
        state.flights = order.flight.segmentGroups.map(function (group) {
            return __assign(__assign({}, group), { segments: group.segments.filter(function (segment) {
                    return (flatten(state.service.allowedSegments).includes(segment.id) && segment.subStatus !== 'Upgrade');
                }), flightId: order.flight.id });
        });
    }
    else {
        state.flights = order.segments;
    }
    state.travellersIds = order.travellers.map(function (traveller) { return traveller.id; });
    state.totalPrice = {
        amount: 0,
        currency: orderCurrency
    };
    return state;
};
