import { __assign } from "tslib";
import * as React from 'react';
import { Flight } from '../../../../index';
import { useTheme } from '../../../../theme';
import MediaQuery from 'react-responsive';
import { TABLET_MIN_WIDTH } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { OverrideComponent } from '../../../../renderProps';
import { checkoutSegmentGroupToFlightPropsAdapter } from '../../../../Flight/adapters';
var SelectedFlight = function (_a) {
    var flight = _a.flight, confirmed = _a.confirmed, canceled = _a.canceled, orderId = _a.orderId, subsidized = _a.subsidized;
    var theme = useTheme('Checkout').CheckoutStyles;
    var t = useTranslation('Checkout').t;
    return (React.createElement("div", { className: theme.selectedFlight__container },
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: theme.selectedFlight__header }, t(flight.segmentGroups.length > 1 ? 'Flights' : 'Flight'))),
        flight.segmentGroups.map(function (group, index) {
            return (React.createElement(OverrideComponent, { key: index, componentProps: __assign({ key: index }, checkoutSegmentGroupToFlightPropsAdapter(orderId, flight, flight.segmentGroups[index], confirmed, subsidized)), component: {
                    renderCheckoutSelectedFlight: Flight
                } }));
        })));
};
export default SelectedFlight;
