import { __assign, __read } from "tslib";
import { useEffect, useReducer } from 'react';
import { upgradeClassStateFactory } from './factory';
import { reducer } from './reducer';
export var useUpgradeFlightClass = function (order) {
    var _a = __read(useReducer(reducer, order, upgradeClassStateFactory), 2), state = _a[0], dispatch = _a[1];
    var countTotalPriceEffect = function () {
        var servicePrice = state.service.price;
        var newPrice = __assign({}, servicePrice);
        newPrice.amount = servicePrice.amount * state.selectedSegments.length;
        dispatch({
            type: 'setTotalPrice',
            payload: newPrice
        });
    };
    useEffect(countTotalPriceEffect, [state.selectedSegments, dispatch]);
    return {
        state: state,
        dispatch: dispatch
    };
};
